import {
  action,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
  toJS,
} from "mobx";
import moment from "moment";
import Cookies from "universal-cookie";
import { baseURL } from "../network/URL";
import { toast } from "react-toastify";
import common from "./common";
import {
  getProfileAndDailyData,
  getProfileAndDailyDataV2,
} from "../network/trackingAPI";
import dayjs from "dayjs";
import { th } from "date-fns/locale";
import { lab, timeHours } from "d3";

class TrackingVTwo {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");
  year = moment().year();

  month = moment().month() + 1;

  isAds = common.isAds;
  selectedAsset = null;
  selectedUid = null;
  selectedAssetName = null;
  selectedAssetId = this.selectedAsset;
  assetProfile = null;
  assetProfiles = [];
  vehicleList = null;
  selectedPlate = "GBL6140C-5005320";
  fmsVehicleLst = null;
  selectedVehicle = null;
  //   vehicleType = { value: "Battery", label: "EV", };
  vehicleType = {};
  vehicleOptions = "";
  showView = "chart";
  trackingDetail = null;
  trackingDetailVTwo = null;
  selectedDetail = null;
  selectedIndex = 0;
  theme = localStorage.getItem("theme");
  showChartView = "CANBus";
  showChartViewVTwo = "CANBus";
  forallCompanyVehicleOptions = [];
  forallCompanyVehicles = null;
  unitSetting = null;
  showComparisonSelect = "individual";
  number_plates = [];
  vehicleIds = [];
  selectedIndex = "";

  sessionExpire = false;
  placeholder = false;
  isLoading = false;

  showTooltip = true;

  // dateFormat = this.unitSetting?.enableCANbus && !this.unitSetting?.enableGPS || !this.unitSetting?.enableCANbus && this.unitSetting?.enableGPS ? "Year" : "Month";
  dateFormat = "Month";
  yearSelected = new Date().getFullYear();

  startDate = "";
  endDate = "";

  startDateFormat = "";
  endDateFormat = "";
  dateOptionsType = "";

  reportDownload = false;

  baselineEmission = true;
  baselineEmissionFactor = true;
  actualEmissionFactor = true;
  co2Emission = true;
  idlingCarbonEmission = true;

  constructor() {
    this.fetchVehicleList = this.fetchVehicleList.bind(this);
    this.fetchAssetProfile = this.fetchAssetProfile.bind(this);
    this.fetchFMSVehicles = this.fetchFMSVehicles.bind(this);
    this.fetchTrackingDetail = this.fetchTrackingDetail.bind(this);
    this.fetchTrackingDetailV2 = this.fetchTrackingDetailV2.bind(this);
    this.fetchGetAllCompanyVehicles =
      this.fetchGetAllCompanyVehicles.bind(this);

    makeObservable(this, {
      theme: observable,
      showChartView: observable,
      showChartViewVTwo: observable,
      selectedIndex: observable,
      selectedDetail: observable,
      unitSetting: observable,
      number_plates: observable,
      showView: observable,
      showComparisonSelect: observable,
      year: observable,
      token: observable,
      companyId: observable,
      trackingDetail: observable,
      trackingDetailVTwo: observable,
      selectedAsset: observable,
      vehicleList: observable,
      assetProfile: observable,
      assetProfiles: observable,
      selectedIndex: observable,
      vehicleIds: observable,
      placeholder: observable,
      isLoading: observable,
      dateFormat: observable,
      startDate: observable,
      endDate: observable,
      startDateFormat: observable,
      endDateFormat: observable,
      reportDownload: observable,

      showTooltip: observable,

      baselineEmission: observable,
      baselineEmissionFactor: observable,
      actualEmissionFactor: observable,
      co2Emission: observable,
      idlingCarbonEmission: observable,
      dateOptionsType: observable,

      handleYearSelect: action.bound,
      handleVehicleSelect: action.bound,
      handleRowSelect: action.bound,
      sessionExpire: observable,
      month: observable,
      handleMonthSelect: action.bound,
      fmsVehicleLst: observable,
      handleVehicleSelected: action.bound,
      selectedVehicle: observable,
      vehicleType: observable,
      handleSelectedVehicleType: action.bound,
      handleSelectedRowForFMS: action.bound,
      handleViewChange: action.bound,
      handleGetVehicleType: action.bound,
      handleSelecteDetail: action.bound,
      handleShowChartView: action.bound,
      handleShowChartViewVTwo: action.bound,
      fetchFMSVehicles: action.bound,
      fetchGetAllCompanyVehicles: action.bound,
      forallCompanyVehicles: observable,
      handleComparisonSelectChange: action.bound,
      fetchAssetProfileAndDailyData: action.bound,
      fetchAssetProfileAndDailyDataV2: action.bound,
      _handleSelectYear: action.bound,
      setSelectDateFormat: action.bound,
      setSelectedIndex: action.bound,
      setPlaceHolder: action.bound,
      setLoading: action.bound,
      _handleGetDaysRange: action.bound,
      _handleSelectCustom: action.bound,
      _handleSelectDay: action.bound,
      setReportDownload: action.bound,
      setShowTooltip: action.bound,
      _handleChartCheckbox: action.bound,
    });
  }

  _handleChartCheckbox(label) {
    switch (label) {
      case "Baseline CO₂e Emission":
        this.baselineEmission = !this.baselineEmission;
        break;
      case "Baseline Emission Factor":
        this.baselineEmissionFactor = !this.baselineEmissionFactor;
        break;
      case "Actual Emission Factor":
        this.actualEmissionFactor = !this.actualEmissionFactor;
        break;
      case "Idling CO₂e Emission":
        this.idlingCarbonEmission = !this.idlingCarbonEmission;
        break;
      default:
        this.co2Emission = !this.co2Emission;
        break;
    }
   
  }
  
  _handleSelectCustom(date, dateString) {
    this.startDateFormat = dateString[0];
    this.endDateFormat = dateString[1];

    this.fetchAssetProfileAndDailyDataV2();
    this.fetchTrackingDetailV2();
  }

  setShowTooltip(value) {
    this.showTooltip = value;
  }

  setReportDownload(value) {
    this.reportDownload = value;
    this.setShowTooltip(false);
    console.log("reportDownload --->", this.reportDownload);
  }

  _handleGetDaysRange() {
    if (this.dateFormat === "Month") {
      this.startDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .startOf("month");
      this.endDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .endOf("month");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY-MM-DD");
      this.endDateFormat = moment(this.endDate.$d).format("YYYY-MM-DD");
    } else if (this.dateFormat === "Year") {
      this.startDate = dayjs().year(this.yearSelected).startOf("year");
      this.endDate = dayjs().year(this.yearSelected).endOf("year");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY-MM-DD");
      this.endDateFormat = moment(this.endDate.$d).format("YYYY-MM-DD");
    } else if (this.dateFormat === "7 Days") {
      let dates = [];
      let currentDate = new Date();
      let week = 7;

      for (let i = 1; i <= week; i++) {
        dates.push(currentDate);
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() - 1);
      }
      this.endDateFormat = moment(dates[0]).format("YYYY-MM-DD");
      this.startDateFormat = moment(dates[dates.length - 1]).format(
        "YYYY-MM-DD"
      );
    } else {
      this.startDate = dayjs()
        .year(this.yearSelected)
        .month(this.month - 1)
        .startOf("month");

      this.startDateFormat = moment(this.startDate.$d).format("YYYY-MM-DD");
      this.endDateFormat = moment(new Date()).format("YYYY-MM-DD");
    }
  }

  _handleSelectDay(date, dateString) {
    if (this.dateFormat === "7 Days") {
      const selectedDate = moment(dateString, "YYYY-MM-DD");
      const startDate = selectedDate.startOf("week").format("YYYY-MM-DD");
      const endDate = selectedDate.endOf("week").format("YYYY-MM-DD");
      let currentDate = moment();
      let dates = [];
      let week = 7;

      // if (moment(endDate, "YYYY-MM-DD").isAfter(currentDate, 'day')) {
      //   for (let i = 1; i <= week; i++) {
      //     dates.push(currentDate);
      //     currentDate = new Date(currentDate);
      //     currentDate.setDate(currentDate.getDate() - 1);
      //   }
      //   this.endDateFormat = moment(dates[0]).format("YYYY-MM-DD");
      //   this.startDateFormat = moment(dates[dates.length - 1]).format(
      //     "YYYY-MM-DD"
      //   );
      //         } else {
      //   this.startDateFormat = startDate;
      //   this.endDateFormat = endDate;
      // }

      this.startDateFormat = startDate;
      this.endDateFormat = endDate;

      this.fetchAssetProfileAndDailyDataV2();
      this.fetchTrackingDetailV2();
    }
  }

  setLoading(value) {
    this.isLoading = value;
  }

  _handleSelectYear(year) {
    this.showView = "chart";

    runInAction(() => {
      this.yearSelected = year;
    });
    this._handleGetDaysRange();
    this.fetchAssetProfileAndDailyDataV2();

    if (this.number_plates.length === 0) {
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    // this.fetchAssetProfileAndDailyData();
    this.fetchAssetProfileAndDailyDataV2();
    if (this.showComparisonSelect === "individual") {
      // this.fetchTrackingDetail();
      this.fetchTrackingDetailV2();
    }
  }

  setSelectDateFormat(value) {
    this.dateFormat = value;
    this._handleGetDaysRange();
    this.fetchAssetProfileAndDailyDataV2();
    this.fetchTrackingDetailV2();
  }

  handleShowChartViewVTwo(view) {
    runInAction(() => {
      this.showChartViewVTwo = view;
    });
  }

  handleShowChartView(view) {
    // if (this.showComparisonSelect === "individual") {
    //   this.showChartView = view;
    // } else {
    //   if (this.showChartViewVTwo === "CANBus") {
    //     this.showChartView = "daily";
    //   } else {
    //     this.showChartView = "monthly";
    //   }
    //   this.fetchAssetProfileAndDailyData();
    //   console.log(this.fetchAssetProfileAndDailyData , 'br twy ll')
    // }
    // console.log("showchartView", this.showChartView);
    this.showChartView = view;
  }

  handleComparisonSelectChange(view) {
    runInAction(() => {
      this.showComparisonSelect = view;
    });
    this.selectedVehicle = this.vehicleOptions[0];
    if (view === "comparison") {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
      this.fetchAssetProfileAndDailyDataV2();
      // this.fetchAssetProfileAndDailyData();
      this.showChartView = "CANBus";
      this.year = moment().year();
      this.month = moment().month() + 1;
    } else {
      this.year = moment().year();
      this.month = moment().month() + 1;
      const filter = this.fmsVehicleLst?.filter(
        (v) => v.id === this.selectedVehicle.value
      )[0];
      this.unitSetting = filter?.unitSetting;
      if (this.unitSetting?.enableCANbus === true) {
        this.showChartViewVTwo = "CANBus";
      } else {
        this.showChartViewVTwo = "manual";
      }
    }
  }

  handleSelecteDetail(value, index) {
    runInAction(() => {
      this.selectedDetail = value;
      this.selectedIndex = index;
    });
  }

  handleViewChange(view) {
    runInAction(() => {
      this.showView = view;
    });
  }

  handleSelectedVehicleType = (value) => {
    this.showView = "chart";
    this.vehicleType = value;
    this.selectedVehicle = "";
    this.vehicleOptions = [];
    this.number_plates = [];
    this.vehicleIds = [];

    this.vehicleOptions = this.fmsVehicleLst
      ?.filter((v) => v.fuel_type.trim() === value.value)
      ?.map((a) => {
        return {
          label: a.grouping,
          value: a.id,
          image: a.image,
        };
      });

    this.selectedVehicle = this.vehicleOptions[0];
    this.unitSetting = this.fmsVehicleLst?.filter(
      (v) => v.id == this.selectedVehicle?.value
    )[0].unitSetting;

    if (
      (this.unitSetting?.enableCANbus && !this.unitSetting?.enableGPS) ||
      (!this.unitSetting?.enableCANbus && this.unitSetting?.enableGPS)
    ) {
      this.dateOptionsType = "Year";
      this.setSelectDateFormat("Year");
    } else {
      this.dateOptionsType = "";
    }

    if (this.number_plates.length === 0) {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
    }

    if (this.showComparisonSelect === "individual") {
      this.number_plates = [this.selectedVehicle?.label];
      this.vehicleIds = [this.selectedVehicle?.value];
      // this.fetchTrackingDetail();
      this._handleGetDaysRange();
      this.fetchAssetProfileAndDailyDataV2();

      this.fetchTrackingDetailV2();
    } else {
      this.fetchAssetProfileAndDailyDataV2();
    }
  };

  handleMonthSelect(month) {
    this.showView = "chart";

    runInAction(() => {
      this.month = month;
    });

    this._handleGetDaysRange();

    if (this.number_plates.length === 0) {
      console.log("number_plates");
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    if (this.showComparisonSelect === "individual") {
      // this.fetchTrackingDetail();
      this.fetchTrackingDetailV2();
      this.fetchAssetProfileAndDailyDataV2();
    } else {
      this.fetchAssetProfileAndDailyDataV2();
    }
    // this.fetchAssetProfileAndDailyData();
  }

  handleYearSelect(year) {
    this.showView = "chart";
    runInAction(() => {
      this.year = year;
    });
    if (this.number_plates.length === 0) {
      this.number_plates.push(this.selectedVehicle?.label);
      this.vehicleIds.push(this.selectedVehicle?.value);
    }
    // this.fetchAssetProfileAndDailyData();
    if (this.showComparisonSelect === "individual") {
      // this.fetchTrackingDetail();
      this.fetchTrackingDetailV2();
      this.fetchAssetProfileAndDailyDataV2();
    } else {
      this.fetchAssetProfileAndDailyDataV2();
    }
  }

  handleRowSelect(select) {
    runInAction(() => {
      this.selectedAsset = select;
      this.selectedUid = select;
      this.selectedAssetId = select;
      // this.selectedAssetName=this.vehicleList.find(v=>v.uid===select).asset_name
    });
    this.fetchAssetProfile();
  }

  handleSelectedRowForFMS = (vehicle) => {
    this.vehicleType = { value: "Battery", label: "EV" };

    this.selectedVehicle = {
      value: vehicle.uid,
      label: vehicle.grouping,
    };

    // this.fetchAssetProfileAndDailyData();
    // this.fetchTrackingDetail();
    this.fetchAssetProfileAndDailyDataV2();
    this.fetchTrackingDetailV2();
  };

  handleVehicleSelected = (value) => {
    this.showView = "chart";
    this.selectedVehicle = value;

    if (this.showComparisonSelect === "individual") {
      this.number_plates = [value.label];
      this.vehicleIds = [value.value];
      // this.fetchTrackingDetail();
      // this.fetchAssetProfileAndDailyData();
      this.fetchAssetProfileAndDailyDataV2();
      this.fetchTrackingDetailV2();
    } else {
      this.number_plates = [];
      this.vehicleIds = [];
      value?.map((data) => {
        this.number_plates.push(data.label);
        this.vehicleIds.push(data.value);
      });
      this.fetchAssetProfileAndDailyDataV2();
      // this.fetchAssetProfileAndDailyData();
    }

    if (this.showComparisonSelect === "individual") {
      const filter = this.fmsVehicleLst?.filter((v) => v.id === value.value)[0];
      this.unitSetting = filter?.unitSetting;
      if (this.unitSetting?.enableCANbus === true) {
        this.showChartViewVTwo = "CANBus";
      } else {
        this.showChartViewVTwo = "manual";
      }
    } else {
      const filter = this.fmsVehicleLst?.map((v) => v.unitSetting);
      this.unitSetting = filter?.map((v) => v);
    }

    if (
      (this.unitSetting?.enableCANbus && !this.unitSetting?.enableGPS) ||
      (!this.unitSetting?.enableCANbus && this.unitSetting?.enableGPS)
    ) {
      this.dateOptionsType = "Year";
      this.setSelectDateFormat("Year");
    } else {
      this.dateOptionsType = "";
    }
  };

  handleVehicleSelect(assetUid, assetName, assetId) {
    runInAction(() => {
      this.selectedUid = assetUid;
      this.selectedAsset = assetId;
      this.selectedAssetName = assetName;
      this.selectedAssetId = assetId;
    });
    this.fetchAssetProfile();
  }

  async fetchVehicleList(props) {
    runInAction(() => {
      this.vehicleList = null;
    });

    let data;
    try {
      const params = this.isAds
        ? {
            companyId: this.companyId,
            siteId: 14,
          }
        : {
            companyId: this.companyId,
          };
      const searchParams = new URLSearchParams(params);

      const response = await fetch(
        `${baseURL}/co2/device/getVehicleList?${searchParams.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.vehicleList = data.asset;
          this.selectedUid = this.vehicleList[0].uid;
          this.selectedAssetId = this.vehicleList[0].asset_id;
          this.unitSetting = this.vehicleList[0].unitSetting;
        });

        this.fetchAssetProfile();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAssetProfileAndDailyData(props) {
    runInAction(() => {
      this.assetProfile = null;
      this.assetProfiles = [];
    });
    const request_body = {
      // vehical_plates: this.number_plates,
      vehicleIds: this.vehicleIds,
      year: this.year,
      month: this.month,
      dataStatus: this.showChartView,
    };

    getProfileAndDailyData(request_body, this.token, (err, data) => {
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          if (this.showComparisonSelect === "individual") {
            this.assetProfile = data[0];
          } else {
            this.assetProfiles = data;
          }
        });
      }
    });
  }

  async fetchAssetProfileAndDailyDataV2() {
    runInAction(() => {
      this.assetProfile = null;
      this.assetProfiles = [];
    });

    const request_body = {
      // vehical_plates: this.number_plates,
      start_date: this.startDateFormat,
      end_date: this.endDateFormat,
      // unitIds: this.vehicleOptions.map(v => v.value),
      unitIds: this.vehicleIds,
      key: this.dateFormat === "Year" ? "year" : "daily",
    };

    getProfileAndDailyDataV2(request_body, this.token, (err, data) => {
      if (err) {
        toast.info(err.message);
        // window.location.pathname = "/";
      } else {
        this.baselineEmission = true;
        this.actualEmissionFactor = true;
        this.co2Emission = true;
        this.baselineEmissionFactor = true;
        this.idlingCarbonEmission = true;

        runInAction(() => {
          if (this.showComparisonSelect === "individual") {
            this.assetProfile = data.payload[0];
            this.handleViewChange("chart");
          } else {
            this.assetProfiles = data.payload;
          }
        });

        // this.unitSetting = {
        //   enableCANbus: this.assetProfile?.enableCANbus,
        //   enableGPS: this.assetProfile?.enableGPS
        // }
      }
    });
  }

  //   async fetchAssetProfileAndDailyData(props) {
  //     runInAction(() => {
  //       this.assetProfile = null;
  //     });
  //     let data;
  //     try {
  //       const response = await fetch(
  //         `${baseURL}/co2/device/profileAndDailyData?year=${this.year}&month=${this.month}
  //             &assetId=${this.selectedVehicle?.value}&vehical_plate=${[this.selectedVehicle?.label]}&companyId=${this.companyId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //             "X-Access-Token": this.token,
  //           },
  //         }
  //       );

  //       data = await response.json();
  //       if (data.message === "Token Expired!") {
  //         toast.info(data.message);
  //         window.location.pathname = "/";
  //       } else {
  //         console.log(data);
  //         runInAction(() => {
  //           this.assetProfile = data;
  //         });
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }

  async fetchTrackingDetail(props) {
    runInAction(() => {
      this.trackingDetail = [];
      this.selectedDetail = null;
    });
    let data;
    try {
      const response = await fetch(
        `${baseURL}/co2/device/getTrackingDetail?year=${this.year}&month=${this.month}&assetId=${this.selectedVehicle?.value}&vehical_plate=${this.selectedVehicle?.label}&companyId=${this.companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );

      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.trackingDetail = data;
          // this.selectedDetail = data[0];
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchTrackingDetailV2() {
    runInAction(() => {
      this.trackingDetailVTwo = [];
      this.selectedDetail = null;
    });
    let data;
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/getTrackingDetail?start_date=${this.startDateFormat}&end_date=${this.endDateFormat}&unitId=${this.selectedVehicle?.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );

      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.trackingDetailVTwo = data.payload;
          // this.selectedDetail = data[0];
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchFMSVehicles(props) {
    runInAction(() => {
      this.fmsVehicleLst = null;
    });
    this.handleGetVehicleType();
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/getVehiclesByUserRole?companyId=${this.companyId}&asset=0`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      let data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          this.fmsVehicleLst = data;
          this.vehicleOptions = this.fmsVehicleLst
            ?.filter((v) => {
              return v.fuel_type.trim() === this.vehicleType.value;
            })
            ?.map((a) => {
              return {
                label: a.grouping,
                value: a.id,
                image: a.image,
              };
            });

          this.selectedVehicle = this.vehicleOptions[0];
          this.unitSetting = this.fmsVehicleLst.filter(
            (v) => v.id == this.selectedVehicle?.value
          )[0].unitSetting;

          if (
            (this.unitSetting?.enableCANbus && !this.unitSetting?.enableGPS) ||
            (!this.unitSetting?.enableCANbus && this.unitSetting?.enableGPS)
          ) {
            this.dateOptionsType = "Year";
            this.setSelectDateFormat("Year");
          } else {
            this.dateOptionsType = "";
          }

          if (this.unitSetting.enableCANbus === true) {
            this.showChartViewVTwo = "CANBus";
          } else {
            this.showChartViewVTwo = "manual";
          }
          if (this.showComparisonSelect === "individual") {
            this.fetchAssetProfileAndDailyDataV2();
            this.fetchTrackingDetailV2();
          } else {
            this.fetchAssetProfileAndDailyData();
            this.fetchTrackingDetail();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async handleGetVehicleType(props) {
    runInAction(() => {
      this.vehicleType = {};
    });
    try {
      const response = await fetch(
        `${baseURL}/co2/device/v2/vehicleType?companyId=${this.companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      let data = await response.json();

      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          console.log("data", data[0].primary_fuel_type);
          if (data) {
            if (
              data[0]?.primary_fuel_type === "" ||
              data[0]?.primary_fuel_type === "Battery"
            ) {
              this.vehicleType = { value: "Battery", label: "EV" };
              this.handleSelectedVehicleType(this.vehicleType);
            } else if (data && data[0]?.primary_fuel_type === "Diesel") {
              this.vehicleType = { value: "Diesel", label: "Diesel" };
              this.handleSelectedVehicleType(this.vehicleType);
            } else if (data && data[0]?.primary_fuel_type === "Petrol") {
              this.vehicleType = { value: "Petrol", label: "Petrol" };
              this.handleSelectedVehicleType(this.vehicleType);
            } else {
              this.vehicleType = { value: "Battery", label: "EV" };
              this.handleSelectedVehicleType(this.vehicleType);
            }
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchGetAllCompanyVehicles(props) {
    // console.log("........................");
    // runInAction(() => {
    //     this.forallCompanyVehicles = null;
    // });
    // try {
    //     console.log('in try ...............');
    //     const response = await fetch(`${baseURL}/co2/device/v2/getVehiclesByUserRole?companyId=${this.companyId}`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             "X-Access-Token": this.token,
    //         },
    //     });
    //     let data = await response.json();
    //     if (data.message === "Token Expired!") {
    //         toast.info(data.message);
    //         window.location.pathname='/';
    //     } else {
    //         runInAction(() => {
    //             this.forallCompanyVehicles = data;
    //             console.log("favdata>>",data, toJS(this.forallCompanyVehicles));
    //             this.forallCompanyVehicleOptions = this.forallCompanyVehicles?.filter((v) => {
    //                 return v.type.trim() === this.vehicleType.value;
    //             })?.map((a) => {
    //                 return {
    //                     label: a.grouping,
    //                     value: a.id,
    //                     image: a.image,
    //                 }
    //             })
    //             this.selectedVehicle = this.forallCompanyVehicleOptions[0];
    //             this.fetchAssetProfileAndDailyData();
    //             this.fetchTrackingDetail();
    //         });
    //     }
    // } catch (e) {
    //     console.log(e)
    // }
  }

  async fetchAssetProfile(props) {
    runInAction(() => {
      this.assetProfile = null;
    });
    const body = {
      deviceId: this.isAds
        ? this.selectedAssetId
          ? this.selectedAssetId
          : this.vehicleList[0]?.asset_id
        : this.selectedUid
        ? this.selectedUid
        : this.vehicleList[0]?.uid,
      siteId: 14,
    };
    let data;
    try {
      const response = this.isAds
        ? await fetch(`${baseURL}/co2/device/profile?year=${this.year}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Access-Token": this.token,
            },
            body: JSON.stringify(body),
          })
        : await fetch(
            `${baseURL}/co2/device/profile?assetId=${this.selectedUid}&year=${this.year}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Access-Token": this.token,
              },
            }
          );
      const selectedAsset = this.vehicleList?.find(
        (asset) => Number(asset.uid) === Number(this.selectedUid)
      );
      data = await response.json();
      if (data.message === "Token Expired!") {
        toast.info(data.message);
        window.location.pathname = "/";
      } else {
        runInAction(() => {
          data.vehicle_number_plate =
            !this.isAds && selectedAsset.vehicle_number_plate;
          this.assetProfile = data;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  setSelectedIndex(value) {
    this.selectedIndex = value;
  }

  setPlaceHolder(value) {
    this.placeholder = value;
  }
}

export default new TrackingVTwo();
