/* eslint-disable react-hooks/exhaustive-deps */
import {
  FuelType,
  TotalVehicles,
  CarbonReduction,
  TotalViews,
} from "../../Components/Dashboard";
import { CompanySelector } from "../../Components/Dashboard/companySelector";
import { Compliance, DataSource } from "../../Components/Dashboard";
import fuelType from "../../Assets/fuelType.json";
import carbonReduction from "../../Assets/carbonReduction1.json";
import React, { useEffect, useState, Component, useContext } from "react";
import { inject, observer } from "mobx-react";
import Store from "../../Store";
import NetZeroChart from "../../Components/Dashboard/netZeroChart";
import CertificateContainer from "../Certificate/CertificateContainer";
import { CarbonAdvoidanceCertificate } from "../../Components/Dashboard";
import { Selector, NotificationCard } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import moment from "moment";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import month from "../../Assets/month.json";
import { Cookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import AccessDeniedPage from "../../Pages/accessdenied";
import REGCertificateContainer from "../Certificate/REGCertificateContainer";
import { useTranslation } from "react-i18next";
import "shepherd.js/dist/css/shepherd.css";
import { DashboardChart } from "../../Components/Dashboard/DashboardChart";
import ScopeCarbonChart from "../../Components/Dashboard/scopeCarbonChart";
import { UnitCount } from "../../Components/Dashboard/UnitCount";
import { toJS } from "mobx";

const DashboardContainer = (props) => {
  const { t } = useTranslation("dashboard");
  const {
    currentYear,
    setCurrentYear,
    totalFuelData,
    currentMonth,
    setCurrentMonth,
    loading,
    _handleQueryParam,
    _handleSelectAssets,
    currentAssets,
    _handleFetchCompanyYears,
    dashboardYears,
    _handleCheckType,
    _handleFetchCalculatedDashboardData,
    Scope1,
    Scope2,
    Scope3,
    monthlyScopeData,
    _handleFetchMonthlyCarbonData,
    selectedYear,
    scope1CheckData,
    scope2CheckData,
    scope3CheckData,
  } = Store.dashboard;

  const { userType, role } = Store.common;
  const { theme } = props;
  // const theme = localStorage.getItem("theme")

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let queryMonth = useQuery().get("month");
  let queryYear = useQuery().get("year");
  let queryAsset = useQuery().get("assets");

  useEffect(() => {
    _handleFetchCompanyYears();

    if (queryYear && queryMonth && queryAsset) {
      _handleQueryParam(queryAsset, queryYear, queryMonth);
    }
  }, []);

  useEffect(() => {
    _handleFetchMonthlyCarbonData(selectedYear);
  }, [selectedYear]);
  // console.log(selectedYear)
  const { width, windowResize, token, lastUpdate, isAds, companyType } =
    props.store.common;
  const { _handleGetLogsNoti } = props.store.adminPanel;
  const cookie = new Cookies();
  const companyId = cookie.get("company") || props.store.common.companyId;

  const [isExpand, setIsExpand] = useState({
    Diesel: false,
    Petrol: false,
    "Compressed Natural Gas": false,
    Electricity: false,
    "Carbon Emission Reductions (ERs)": false,
    "Carbon Offsets": false,
    "Renewable Energy Certificates (RECs)": false,
  });

  const cardStyle = {
    background: Colors[theme].background,
    boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",
    padding: "10px",
    width: "250px",
    borderRadius: "15px",
    marginBottom: 8,
    color: invertDark(theme),
    fontSize: 12,
  };

  const scope1_currentCo2EmissionEhr = totalFuelData
    ? totalFuelData?.scope.scope1.currentCo2EmissionEHr
    : 0;
  const scope2_currentCo2EmissionEhr = totalFuelData
    ? totalFuelData?.scope.scope2.currentCo2EmissionEHr
    : 0;
  const scope3_currentCo2EmissionEhr = totalFuelData
    ? totalFuelData?.scope.scope3.currentCo2EmissionEHr
    : 0;

  const scope1_baselineCo2EmissionEHr = totalFuelData
    ? totalFuelData?.scope.scope1.baselineCo2EmissionEHr
    : 0;
  const scope2_baselineCo2EmissionEHr = totalFuelData
    ? totalFuelData?.scope.scope2.baselineCo2EmissionEHr
    : 0;
  const scope3_baselineCo2EmissionEHr = totalFuelData
    ? totalFuelData?.scope.scope3.baselineCo2EmissionEHr
    : 0;

  const scope1_baseLineCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope1.baselineCo2Emission
    : 0;
  const scope2_baseLineCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope2.baselineCo2Emission
    : 0;
  const scope3_baseLineCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope3.baselineCo2Emission
    : 0;

  const scope1_currentCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope1.currentCo2Emission
    : 0;
  const scope2_currentCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope2.currentCo2Emission
    : 0;
  const scope3_currentCo2Emission = totalFuelData
    ? totalFuelData?.scope.scope3.currentCo2Emission
    : 0;

  const currentCo2Emission = totalFuelData
    ? totalFuelData.currentCo2Emission
    : 0;

  const offset = totalFuelData ? totalFuelData.offset : 0;
  const baseLineCo2Emission = totalFuelData
    ? // ? totalFuelData &&
      //   Object.values(totalFuelData.consumptionsByFuelType).reduce(
      //     (c, a) => c + Number(a.baselineCo2Emission),
      //     0
      //   )
      totalFuelData?.baselineCo2Emission
    : 0;

  const scope = {
    scope_1: {
      baseline_emission: Number(scope1_baseLineCo2Emission) || 0,
      carbon_emission: Number(scope1_currentCo2Emission) || 0,
      baseline_emission_E_Hr: Number(scope1_baselineCo2EmissionEHr) || 0,
      carbon_emission_E_Hr: Number(scope1_currentCo2EmissionEhr) || 0,
    },
    scope_2: {
      baseline_emission: Number(scope2_baseLineCo2Emission) || 0,
      carbon_emission: Number(scope2_currentCo2Emission) || 0,
      baseline_emission_E_Hr: Number(scope2_baselineCo2EmissionEHr) || 0,
      carbon_emission_E_Hr: Number(scope2_currentCo2EmissionEhr) || 0,
    },
    scope_3: {
      baseline_emission: Number(scope3_baseLineCo2Emission) || 0,
      carbon_emission: Number(scope3_currentCo2Emission) || 0,
      baseline_emission_E_Hr: Number(scope3_baselineCo2EmissionEHr) || 0,
      carbon_emission_E_Hr: Number(scope3_currentCo2EmissionEhr) || 0,
    },
  };

  const _handleExpand = (title) => {
    var obj = { ...isExpand };
    obj[title] = !obj[title];
    setIsExpand(obj);
  };

  useEffect(() => {
    const isConsumed = (type) => {
      return isAds
        ? totalFuelData && totalFuelData?.consumptionsByFuelType[type]
        : totalFuelData && totalFuelData.consumptionsByFuelType[type];
    };
    setIsExpand({
      Diesel: isConsumed("Diesel") && true,
      Petrol: isConsumed("Petrol") && true,
      "Compressed Natural Gas": isConsumed("Compressed Natural Gas") && true,
      Electricity: isConsumed("Electricity") && true,
      "Carbon Emission Reductions (ERs)":
        isConsumed("Carbon Emission Reductions (ERs)") && true,
      "Carbon Offsets": isConsumed("Carbon Offsets") && true,
      "Renewable Energy Certificates (RECs)":
        isConsumed("Renewable Energy Certificates (RECs)") && true,
    });
  }, [loading, theme, totalFuelData]);

  useEffect(() => {
    _handleFetchCalculatedDashboardData();
  }, [currentAssets]);

  useEffect(() => {
    _handleGetLogsNoti();
  }, []);

  useEffect(() => {
    if (lastUpdate === 0) _handleFetchCalculatedDashboardData();
  }, [lastUpdate]);

  useEffect(() => {
    windowResize();
  }, [width]); // eslint-disable-line react-hooks/exhaustive-deps
  const fuelTypeDetail = fuelType.fuelType;
  const carbonReductionDetail = carbonReduction.carbonReduction;

  const data = [
    {
      data: 2,
      Scope1: scope1_baseLineCo2Emission || 0,
      Scope2: scope2_baseLineCo2Emission || 0,
      Scope3: scope3_baseLineCo2Emission || 0,
    },
    {
      data: 1,
      Scope1: scope1_currentCo2Emission || 0,
      Scope2: scope2_currentCo2Emission || 0,
      Scope3: scope3_currentCo2Emission || 0,
    },
  ];
  const allKeys = ["Scope1", "Scope2", "Scope3"];
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    setCurrentData(data);
  }, [
    currentYear,
    currentCo2Emission,
    scope1_currentCo2Emission,
    scope2_currentCo2Emission,
    scope3_currentCo2Emission,
    baseLineCo2Emission,
    scope1_baseLineCo2Emission,
    scope2_baseLineCo2Emission,
    scope3_baseLineCo2Emission,
    Scope1,
    Scope2,
    Scope3,
    currentMonth,
  ]);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "class-1 class-2",
      scrollTo: { behavior: "smooth", block: "center" },
    },

    useModalOverlay: true,
  };

  // function Button() {
  //    const tour = useContext(ShepherdTourContext);

  //   return (
  //     <button className="button dark" onClick={tour.start}>
  //       Start Tour
  //     </button>
  //   );
  // }
  return (
    // certificateView ? (
    //   <CertificateContainer />
    // ) : regView ? (
    //   <REGCertificateContainer />
    // ) : (
    <>
      {/* <div> */}
      {/* <ShepherdTour steps={steps} tourOptions={tourOptions}>
          <TourMethods>
            {tourContext => <Start startTour={tourContext} />}
          </TourMethods>
        </ShepherdTour> */}
      {/* <ShepherdTour steps={steps} tourOptions={tourOptions}>
          <Button />
        </ShepherdTour> */}
      {/* </div> */}
      <span
        style={{
          fontSize: 23,
          color: theme === "dark" ? Colors["light"].background : Colors.Dark[2],
        }}
        className="dashboard-guide"
      >
        {t("Organisation-level Carbon Emission Dashboard")}
        {/* <Tour/> */}
      </span>
      {userType === "co2xlite" ? (
        <AccessDeniedPage
          message={
            "If you are interested in gaining access to Organization-level Carbon Emission Dashboard, please approach your CO2X account manager for details."
          }
        />
      ) : (
        <>
          {/* <div className="d-flex flex-column flex-md-row justify-content-md-between flex-wrap pt-2 align-items-center pb-2"> */}
          <div className="container-fluid ">
            <div className="row">
              {/* <div className="align-self-start mb-2 mb-md-0"> */}
              <div className="title-org-guide col-lg-6 col-md-6 col-sm-12 mb-2 mb-md-0 p-0">
                <CompanySelector
                  theme={theme}
                  label={t("Organisation") + ": "}
                />
              </div>

              {dashboardYears && (
                <div className="asset-org-guide col-lg-6 col-md-12 col-sm-12">
                  <TimeSelectors
                    width={width}
                    _handleYearClick={setCurrentYear}
                    _handleMonthClick={setCurrentMonth}
                    token={token}
                    companyId={companyId}
                    selectedYear={queryYear ? queryYear : currentYear}
                    selectedMonth={queryMonth ? queryMonth : currentMonth}
                    seletedAssets={queryAsset ? queryAsset : currentAssets}
                    _handleSelectAssets={_handleSelectAssets}
                    companyyearlydata={dashboardYears}
                  />
                </div>
              )}
            </div>
          </div>
          {!isAds ? (
            totalFuelData && !loading ? (
              <>
                <div className="row">
                  <div className="col-md-3 " style={{ paddingTop: 38 }}>
                    {role === "super_admin" ? (
                      <div className="total-org-guide">
                        <UnitCount theme={theme} seletedAssets={queryAsset ? queryAsset : currentAssets}/>
                      </div>
                    ) : (
                      <div className="total-org-guide">
                        <TotalViews
                          _handleSelectAssets={_handleSelectAssets}
                          theme={theme}
                        />
                      </div>
                    )}
                    <div className="total-org-guide">
                      <Compliance />
                    </div>
                  </div>

                  <div
                    className="col-md-3 fueltype-org-guide"
                    style={{ paddingTop: 38 }}
                  >
                    {fuelTypeDetail.map((f, k) => (
                      <div className="pb-3" key={k}>
                        <FuelType
                          _handleExpand={_handleExpand}
                          isExpand={isExpand}
                          _companyType={companyType}
                          outputs={[
                            {
                              data: fuelTypeDetail[k],
                              theme: localStorage.getItem("theme"),
                            },
                          ]}
                        />
                      </div>
                    ))}
                  </div>

                  <div
                    className="col-md-3 chart-org-guide"
                    style={{ paddingTop: 38, paddingLeft: 58 }}
                  >
                    <TimeCard
                      width={width}
                      from={totalFuelData.from}
                      to={totalFuelData.to}
                      theme={theme}
                    />
                    <div className="mt-5">
                      <DashboardChart
                        theme={theme}
                        scope={scope}
                        companyType={companyType}
                      />
                    </div>
                  </div>

                  <div
                    className=" col-md-3 "
                    style={{
                      paddingTop: width < 769 ? 20 : 80,
                      paddingLeft: width < 769 ? "" : "95px",
                    }}
                  >
                    <div style={cardStyle}>
                      <label
                        className=""
                        style={{
                          border:
                            theme === "dark"
                              ? toJS(scope1CheckData) === 0
                                ? "2px solid #4F5C73"
                                : "1px solid white"
                              : toJS(scope1CheckData) === 0
                              ? "2px solid silver"
                              : "1px solid black",
                          cursor:
                            toJS(scope1CheckData) === 0
                              ? "not-allowed"
                              : "pointer",
                          margin: "0px 0px 11px",
                          display: "block",
                          borderRadius: "5px",
                          color:
                            theme === "dark"
                              ? toJS(scope1CheckData) === 0
                                ? ""
                                : "#ffffff"
                              : "rgb(0, 0, 0)",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            padding: "5px",
                            color: toJS(scope1CheckData) === 0 ? "#4F5C73" : "",
                          }}
                        >
                          <span className="">Scope 1</span>
                          <input
                            id="Scope1"
                            checked={toJS(scope1CheckData) === 0 ? "" : Scope1}
                            onChange={(e) => {
                              if (toJS(scope1CheckData) !== 0) {
                                _handleCheckType(e, "Scope1");
                              }
                            }}
                            className="me-1"
                            type="checkbox"
                            name="Scope1"
                            style={{
                              cursor:
                                toJS(scope1CheckData) === 0
                                  ? "not-allowed"
                                  : "pointer",
                              width: "25px",
                              height: "22px",
                              accentColor: "#4FAE32",
                            }}
                          />
                        </div>
                      </label>
                      <label
                        className=""
                        style={{
                          border:
                            theme === "dark"
                              ? toJS(scope2CheckData) === 0
                                ? "2px solid #4F5C73"
                                : "1px solid white"
                              : toJS(scope2CheckData) === 0
                              ? "2px solid silver"
                              : "1px solid black",
                          cursor:
                            toJS(scope2CheckData) === 0
                              ? "not-allowed"
                              : "pointer",
                          margin: "0px 0px 11px",
                          display: "block",
                          borderRadius: "5px",
                          color:
                            theme === "dark"
                              ? toJS(scope2CheckData) === 0
                                ? ""
                                : "#ffffff"
                              : "rgb(0, 0, 0)",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            padding: "5px",
                            color: toJS(scope2CheckData) === 0 ? "#4F5C73" : "",
                          }}
                        >
                          <span className="">Scope 2</span>
                          <input
                            id="Scope2"
                            checked={toJS(scope2CheckData) === 0 ? "" : Scope2}
                            onChange={(e) => {
                              if (toJS(scope2CheckData) !== 0) {
                                _handleCheckType(e, "Scope2");
                              }
                            }}
                            className="me-1"
                            type="checkbox"
                            name="Scope2"
                            style={{
                              cursor:
                                toJS(scope2CheckData) === 0
                                  ? "not-allowed"
                                  : "pointer",
                              width: "25px",
                              height: "22px",
                              accentColor: "#4FAE32",
                            }}
                          />
                        </div>
                      </label>
                      <label
                        className=""
                        style={{
                          border:
                            theme === "dark"
                              ? toJS(scope3CheckData) === 0
                                ? "2px solid #4F5C73"
                                : "1px solid white"
                              : toJS(scope3CheckData) === 0
                              ? "2px solid silver"
                              : "1px solid black",
                          cursor:
                            toJS(scope3CheckData) === 0
                              ? "not-allowed"
                              : "pointer",
                          margin: "0px 0px 0px",
                          display: "block",
                          borderRadius: "5px",
                          color:
                            theme === "dark"
                              ? toJS(scope3CheckData) === 0
                                ? ""
                                : "#ffffff"
                              : "rgb(0, 0, 0)",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            padding: "5px",
                            color: toJS(scope3CheckData) === 0 ? "#4F5C73" : "",
                          }}
                        >
                          <span className="">Scope 3</span>
                          <input
                            id="Scope3"
                            checked={toJS(scope3CheckData) === 0 ? "" : Scope3}
                            onChange={(e) => {
                              if (toJS(scope3CheckData) !== 0) {
                                _handleCheckType(e, "Scope3");
                              }
                            }}
                            className="me-1"
                            type="checkbox"
                            name="Scope3"
                            style={{
                              cursor:
                                toJS(scope3CheckData) === 0
                                  ? "not-allowed"
                                  : "pointer",
                              width: "25px",
                              height: "22px",
                              accentColor: "#4FAE32",
                            }}
                          />
                        </div>
                      </label>
                    </div>

                    <div className="pt-3">
                      {/* scope1 */}
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope1CheckData) === 0
                                  ? "silver"
                                  : "#81D4FA",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope1CheckData) === 0
                                  ? "silver"
                                  : "#81D4FA",
                            }}
                          >
                            Scope 1 Baseline CO₂e Emission
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope1CheckData) === 0
                                  ? "silver"
                                  : "#F1B04C",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope1CheckData) === 0
                                  ? "silver"
                                  : "#F1B04C",
                            }}
                          >
                            Scope 1 Actual CO₂e Emission
                          </span>
                        </div>
                      </div>

                      {/* scope2 */}
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope2CheckData) === 0
                                  ? "silver"
                                  : "#64B5F6",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope2CheckData) === 0
                                  ? "silver"
                                  : "#64B5F6",
                            }}
                          >
                            Scope 2 Baseline CO₂e Emission
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope2CheckData) === 0
                                  ? "silver"
                                  : "#ED9106",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope2CheckData) === 0
                                  ? "silver"
                                  : "#ED9106",
                            }}
                          >
                            Scope 2 Actual CO₂e Emission
                          </span>
                        </div>
                      </div>

                      {/* scope3 */}
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope3CheckData) === 0
                                  ? "silver"
                                  : "#4369b9",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope3CheckData) === 0
                                  ? "silver"
                                  : "#4369b9",
                            }}
                          >
                            Scope 3 Baseline CO₂e Emission
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between py-1">
                        <div className="d-flex justify-content-center">
                          <canvas
                            style={{
                              width: "18px",
                              height: "18px",
                              background:
                                toJS(scope3CheckData) === 0
                                  ? "silver"
                                  : "#D77103",
                              marginTop: "2px",
                            }}
                          />
                          <span
                            className="ps-2"
                            style={{
                              color:
                                toJS(scope3CheckData) === 0
                                  ? "silver"
                                  : "#D77103",
                            }}
                          >
                            Scope 3 Actual CO₂e Emission
                          </span>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-row justify-content-between py-1">
                          <div className="d-flex justify-content-center">
                            <canvas
                              style={{
                                width: "18px",
                                height: "18px",
                                background: toJS(scope3CheckData) === 0 ?"silver":"#F1B04C",
                                marginTop: "2px",
                              }}
                            />
                            <span className="ps-2" style={{ color:toJS(scope3CheckData) === 0 ?'silver': "#F1B04C" }}>
                              Scope 1 Actual CO₂e Emission
                            </span>
                          </div>
                        </div> */}
                    </div>
                    {/* <div style={{ paddingTop: 38 }}>
                      <div className="pb-3" style={{ width: "100%" }}>
                        {role === "user" ? (
                          <></>
                        ) : (
                          <div className="certiPage-org-guide">
                            <CarbonAdvoidanceCertificate theme={theme}/>
                          </div>
                        )}
                      </div>
                      {carbonReductionDetail.map((f, k) => {
                        return (''
                          // <div className="pb-3" key={k}>
                          //   <CarbonReduction
                          //     _handleExpand={_handleExpand}
                          //     _handleViewREGCertificate={_handleViewREGCertificate}
                          //     companyId={companyId}
                          //     isExpand={isExpand}
                          //     outputs={[
                          //       {
                          //         data: carbonReductionDetail[k],
                          //       },
                          //     ]}
                          //   />
                          // </div>
                        );
                      })}
                    </div> */}
                    {/* </div> */}
                    {/* <div className="ps-0 mb-4 mx-1 d-flex justify-content-center align-items-center" style={{ height: '450px', background: theme === 'dark' ? Colors.dark.background : '', borderRadius: '10px',  boxShadow: "2px 4px 11px rgba(0,0,0,0.25)",}}>
                    <ResponsiveContainer width="100%" height="90%">
                      <LineChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 6 }} />
                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" activeDot={{ r: 6 }} />
                        <Line type="monotone" dataKey="rv" stroke="orange" activeDot={{ r: 6 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div> */}
                    {/* </div> */}
                  </div>
                </div>

                <NotificationCard theme={theme} lastUpdate={lastUpdate} />
                <ScopeCarbonChart
                  monthlyScopeData={monthlyScopeData}
                  scope={scope}
                  dashboardYear={selectedYear}
                  companyType={companyType}
                />
              </>
            ) : (
              <CO2_Loading text="Loading..." />
            )
          ) : totalFuelData && !loading ? (
            <div
              className="d-flex justify-content-between pt-2"
              // style={{ paddingRight: 30 }}
            >
              <div className="pe-3" style={{ paddingTop: 38 }}>
                <TotalViews theme={theme} />
                <div className="pb-3">
                  <Compliance />
                </div>
                <DataSource />
              </div>
              <div className="pe-3" style={{ paddingTop: 38 }}>
                {fuelTypeDetail.map((f, k) => {
                  return (
                    <div className="pb-3" key={k}>
                      <FuelType
                        _handleExpand={_handleExpand}
                        isExpand={isExpand}
                        outputs={[
                          {
                            data: fuelTypeDetail[k],
                            theme: localStorage.getItem("theme"),
                          },
                        ]}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="pe-3" style={{ paddingTop: 38 }}>
                <TimeCard
                  width={width}
                  from={totalFuelData?.from}
                  to={totalFuelData?.to}
                  theme={theme}
                />
                {/* <NetZeroChart
                  currentMonth={currentMonth}
                  data={currentData}
                  keys={allKeys}
                  _handleYearClick={setCurrentYear}
                  currentYear={currentYear}
                  currentCo2Emission={currentCo2Emission}
                  scope1_currentCo2Emission={scope1_currentCo2Emission}
                  scope2_currentCo2Emission={scope2_currentCo2Emission}
                  scope3_currentCo2Emission={scope3_currentCo2Emission}
                  baseLineCo2Emission={baseLineCo2Emission}
                  scope1_baseLineCo2Emission={scope1_baseLineCo2Emission}
                  scope2_baseLineCo2Emission={scope2_baseLineCo2Emission}
                  scope3_baseLineCo2Emission={scope3_baseLineCo2Emission}
                  offset={offset}
                  Scope1={Scope1}
                  Scope2={Scope2}
                  Scope3={Scope3}
                /> */}
                <div className="pe-3 mt-5">
                  <DashboardChart theme={theme} scope={scope} />
                </div>
              </div>
              {/* <div style={{ paddingTop: 38 }}>
                <div className="pb-3" style={{ width: "100%" }}>
                  {role === "user" ? (
                    <></>
                  ) : (
                    <CarbonAdvoidanceCertificate theme={theme} />
                  )}
                </div>
                {carbonReductionDetail.map((f, k) => {
                  return (
                    <div className="pb-3" key={k}>
                      <CarbonReduction
                        _handleExpand={_handleExpand}
                        isExpand={isExpand}
                        outputs={[
                          {
                            data: carbonReductionDetail[k],
                          },
                        ]}
                      />
                    </div>
                  );
                })}
              </div> */}
              <NotificationCard theme={theme} lastUpdate={lastUpdate} />
            </div>
          ) : (
            <CO2_Loading text="Loading..." />
          )}
        </>
      )}
    </>
  );
};
export default inject("store")(observer(DashboardContainer));

const TimeCard = ({ from, to, theme, width }) => {
  const { t } = useTranslation("dashboard");
  return (
    <div
      className="text-center pb-2 pt-1 rounded"
      style={{
        //position: width > 1480 && "absolute",
        top: width > 1480 && 130,
        fontSize: 14,
        width: "375px",
        background: theme === "dark" ? Colors.lightBlue : Colors.fuelLight,
        color: invertDark(theme),
      }}
    >
      <i>
        {from && to ? (
          <>
            {t("Result from")} {moment(from).format("DD-MMM-yy")} {t("to")}{" "}
            {moment(to).format("DD-MMM-yy")}
          </>
        ) : (
          <>{t("No Result")}</>
        )}
      </i>
    </div>
  );
};

const TimeSelectors = (props) => {
  const { t } = useTranslation("dashboard");
  const {
    token,
    companyId,
    _handleYearClick,
    _handleMonthClick,
    _handleSelectAssets,
    seletedAssets,
    selectedYear,
    selectedMonth,
    width,
    companyyearlydata,
  } = props;
  const history = useHistory();

  const [year, setYear] = useState();

  useEffect(() => {
    const data = companyyearlydata.find((v) => {
      return v.value == selectedYear;
    });

    setYear(data);
  }, [companyyearlydata, selectedYear]);

  function _replaceRoute(assets, year, month) {
    history.replace({
      pathname: "organisation-level-carbon-emission-dashboard",
      search: `?assets=${assets}&year=${year}&month=${month}`,
      state: { isActive: true },
    });
  }
  return (
    <div
      // style={{ paddingLeft: width > 701 && width < 921 ? 80 : 0 }}
      className={`row m-0 d-flex ${
        width <= 912 ? "justify-content-start" : "justify-content-end"
      }`}
    >
      {/* <div className="ps-0 col-lg-4 col-md-4 col-sm-12 mb-2" style={{ width: 300 }}> */}
      <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
        <Selector
          isSearchable={false}
          label={t("Assets Type")}
          options={assetsTypesOption}
          // value={yearOption[selectedYear]}
          value={
            assetsTypesOption.filter((v) => {
              return v.value === seletedAssets;
            })[0]
          }
          _handleSelect={(e) => {
            _handleSelectAssets(e.value);
            _replaceRoute(e.value, selectedYear, selectedMonth);
          }}
        />
      </div>

      {/* <div className="ps-0 col-lg-4 col-md-3 col-sm-6" style={{ width: 120 }}> */}
      <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
        <Selector
          isSearchable={false}
          label={t("Year")}
          options={companyyearlydata}
          value={year ? year : { label: "All", value: 0 }}
          _handleSelect={(e) => {
            _handleYearClick(e.value, token, companyId);
            _replaceRoute(seletedAssets, e.value, selectedMonth);
          }}
        />
      </div>
      {/* <div className="pe-0 col-lg-4 col-md-3 col-sm-6" style={{ width: 180 }}> */}
      <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
        <Selector
          isSearchable={false}
          disabled={selectedYear === 0 && true}
          label={t("Month")}
          defaultValue={month[0]}
          value={selectedYear === 0 ? month[0] : month[selectedMonth]}
          options={selectedYear === 0 ? month[0] : month}
          _handleSelect={(e) => {
            _handleMonthClick(e.value, token, companyId);
            _replaceRoute(seletedAssets, selectedYear, e.value);
          }}
        />
      </div>
    </div>
  );
};

const assetsTypesOption = [
  {
    value: "0",
    label: "All",
  },
  {
    value: "vehicle",
    label: "Vehicles",
  },
  {
    value: "equipment",
    label: "Equipment",
  },
  {
    value: "infrastructure",
    label: "Infrastructure",
  },
];

const yearOption = [
  {
    value: 0,
    label: "All",
    isdisabled: true,
  },
  {
    value: "2024",
    label: "2024",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
    isdisabled: true,
  },
];
