import React from "react";
import { My_Button, My_Input } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { useHistory } from "react-router";

const UserLogin = (props) => {
  const { errStyle } = props;
  const history = useHistory();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <form
          className="col-lg-4 col-md-8 col-sm-10 shadow p-4"
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "15px",
          }}
        >
          <div className="form-group text-center m-0">
            <img src="CO2_Logo.png" alt="logo" />
            <p
              style={{
                fontSize: 28,
                fontWeight: "lighter",
                color: Colors.Dark[4],
              }}
            >
              Login Your Account
            </p>
            {/* <p
              style={{
                fontSize: 18,
                fontWeight: "lighter",
                color: Colors.Dark[4],
              }}
            >
              (Development)
            </p> */}
            <div className="py-3 text-left">
              {props.store.login.userErr === undefined ? null : (
                <div
                  className="d-flex flex-row justify-content-end"
                  style={{ ...errStyle }}
                >{`*${props.store.login.userErr}`}</div>
              )}
              <My_Input
                disabled={props.store.login.isDisabled}
                required={true}
                id={"email"}
                type={"email"}
                placeHolder={"e-mail"}
                value={props.store.login.eMail}
                onChange={(e) => props.store.login._handleEmailChange(e)}
              />
              <div className="pt-3">
                {props.store.login.passwordErr === undefined ? null : (
                  <div
                    className="d-flex flex-row justify-content-end"
                    style={{ ...errStyle }}
                  >{`*${props.store.login.passwordErr}`}</div>
                )}
                <My_Input
                  disabled={props.store.login.isDisabled}
                  required={true}
                  id={"password"}
                  type={props.store.login.visible ? "text" : "password"}
                  placeHolder={"Password"}
                  value={props.store.login.password}
                  onChange={(e) => props.store.login._handlePwdChange(e)}
                />
                <span
                  style={{
                    float: "right",
                    position: "relative",
                    marginTop: "-55px",
                    fontSize: "18px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => props.store.login._handleView()}
                >
                  <i
                    className={`fa fa-eye${
                      props.store.login.visible ? "-slash" : ""
                    } py-4 text-secondary`}
                  />
                </span>
              </div>
            </div>
            <hr
              style={{
                color: "#dadde1",
                backgroundColor: Colors.Dark[3],
                height: 0.5,
                borderColor: "#dadde1",
              }}
            />
            <My_Button
              disabled={props.store.login.isDisabled}
              text={"LOGIN"}
              type={"submit"}
              id={"Login"}
              onClick={(e) => {
                props.store.login._handleSubmit(e, props);
              }}
              style={{ width: "100%" }}
              rightIcon={
                props.store.login.isDisabled ? (
                  <i className="fas fa-spinner fa-spin ml-2"></i>
                ) : (
                  <i className="fa fa-sign-in-alt pl-2" />
                )
              }
            />
          </div>
          {/* <div className="pt-3 text-center" style={{color:'#337dff', fontSize: 12, cursor:'pointer'}} onClick={()=>history.push('/register')}>
            Don't have an account?<span className="text-white ps-2">Sign Up</span></div> */}
        </form>
      </div>
    </div>
  );
};

export default inject("store")(observer(UserLogin));
