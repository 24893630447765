import e from "cors";
import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Store from ".";
import { baseURL } from "../network/URL";
import Common from "./common";
import $ from "jquery";
// import { toJS } from "mobx";

import { AssetFormValidation } from "../helper/formValidation";
import {
  getAllAssetType,
  getAllScheduleList,
  updateDevice,
  getAlgorithmParam,
  addNewDevice,
  removeDevice,
  editDeviceAPI,
  editMinMaxDeviceAPI,
  getUnits,
  getLvlCompanies,
  changeOwner,
  editDeviceMinMaxThreshold,
  getDeviceThreshold,
  getEmissionTypeUnits
} from "../network/assetProfileAPI";
import { baseIMGURL } from "../network/URL";

class AssetProfile {
  cookies = new Cookies();
  token = this.cookies.get("accessToken");
  companyId = this.cookies.get("company");

  deviceList = null;
  typeOptions = null;
  scheduleListOptions = null;
  selectedType = 0;
  selectedSchedule = 0;
  algorithmParam = [];
  algorithmParamOptions = null;
  selectedAlgorithm = 0;
  selectedDeviceData = [];
  newEditData = null;
  currentAssets = "0";
  deviceData = {};
  deviceMinMaxData = {};
  deviceDataforJQdataTable = {};
  deleteMode = false;
  toDelete = { uid: null, license_plate: null };
  modalClose = false;
  validateError = null;
  deviceImage = null;
  disableAddVehicle = true;
  disableAddMinMaxVehicle = true;
  editMode = false;
  editMinMaxMode = false;
  addNewEnable = false;
  photo = "";
  newUpload = false;
  lvlCompanies = [];
  selectedCompany = null;
  gpsChecked = null;
  canbusChecked = null;
  searchKey = "";
  searchedUnits = null;
  searchValue = false;
  selectedAssetType = "";
  selectedDetail = null;
  selectedIndex = 0;
  unitData = "";
  emissionTypeUnits = null;

  constructor() {
    makeObservable(this, {
      addNewEnable: observable,
      companyId: observable,
      token: observable,
      deviceList: observable,
      selectedType: observable,
      typeOptions: observable,
      selectedSchedule: observable,
      scheduleListOptions: observable,
      newEditData: observable,
      selectedDeviceData: observable,
      algorithmParam: observable,
      algorithmParamOptions: observable,
      selectedAlgorithm: observable,
      currentAssets: observable,
      deviceData: observable,
      deviceMinMaxData: observable,
      deviceDataforJQdataTable: observable,
      deleteMode: observable,
      toDelete: observable,
      modalClose: observable,
      validateError: observable,
      deviceImage: observable,
      disableAddVehicle: observable,
      disableAddMinMaxVehicle: observable,
      editMode: observable,
      editMinMaxMode: observable,
      photo: observable,
      lvlCompanies: observable,
      selectedCompany: observable,
      gpsChecked: observable,
      canbusChecked: observable,
      searchKey: observable,
      searchedUnits: observable,
      searchValue: observable,
      selectedAssetType: observable,
      selectedDetail: observable,
      selectedIndex: observable,
      unitData: observable,
      emissionTypeUnits: observable,

      _fetchUnits: action.bound,
      fetchAssetType: action.bound,
      _handleSelectType: action.bound,
      _handleTextChange: action.bound,
      fetchUpdateDevice: action.bound,
      _handleSelectDevice: action.bound,
      fetchScheduleList: action.bound,
      fetchAlgorithmParam: action.bound,
      _handleSelectAssets: action.bound,
      _handleUploadImage: action.bound,
      _handleAddNewDevice: action.bound,
      _handleAddNewMinMaxDevice: action.bound,
      _setDeleteMode: action.bound,
      _setToDelete: action.bound,
      _handleEdit: action.bound,
      _handleEditMinMax: action.bound,
      setEditMode: action.bound,
      setEditMinMaxMode: action.bound,
      _handleCloseModel: action.bound,
      _fetchLvlCompanies: action.bound,
      _handleSelectChange: action.bound,
      _handleChangeOwner: action.bound,
      setGpsChecked: action.bound,
      setCanbusChecked: action.bound,
      _handleSearchChange: action.bound,
      setSearchedUnits: action.bound,
      _handleSelectAssetType: action.bound,
      _handleSelectedDetail: action.bound,
      _handleSetUnitData: action.bound,
      _handleGetEmissionTypeUnits: action.bound,
    });
    // this.deviceMinMaxData = {
    //   fuel_consumed_min: "0",
    //   fuel_consumed_max: "60",
    //   battery_consumed_min: "0",
    //   battery_consumed_max: "80",
    //   mileage_min: "0",
    //   mileage_max: "50",
    //   speed_min: "0",
    //   speed_max: "90",
    // };

  }

  _handleGetEmissionTypeUnits = () => {
    getEmissionTypeUnits(this.token, (err, data) => {
      if (data) this.emissionTypeUnits = data.payload;
    })
  }


  _handleSelectChange = (value) => {
    this.selectedCompany = value;
  };

  _handleChangeOwner = (unitId, Gps, Canbus) => {
    let enableData = {
      enableGPS: Gps,
      enableCANbus: Canbus,
    };
    changeOwner(
      Common.token,
      unitId,
      this.selectedCompany,
      enableData,
      (err, data) => {
        if (data.response) {
          toast.success(data.response);
          this._fetchUnits();
        } else {
          toast.error(err.error);
        }
      }
    );
  };

  _handleSelectAssets = async (value, props) => {
    this.currentAssets = value;

    if (this.currentAssets === "0") {
      this.addNewEnable = true;
    } else if (this.currentAssets === "vehicle") {
      this.addNewEnable = true;
    } else {
      this.addNewEnable = false;
    }
    this._fetchUnits();
  };

  _handleSelectType = (e, tableIndex) => {
    this.selectedType = e.value;
  };

  _handleTextChange = (index, key, subKey, value) => {
    const newDevice = this.deviceList[index];

    // if(key === 'carrying_capacity'){
    //   if(newDevice.cargo_type === "" || !newDevice.carrying_capacity){
    //     this.validateError.cargo_type = "Please sekect..."
    //   }
    // }

    if (key === "info") {
      newDevice[key][subKey] = value;
    } else {
      newDevice[key] = value;
    }
    this.deviceList[index] = newDevice;
    
  };

  fetchUpdateDevice = (tableIndex) => {
    const newDevice = this.newEditData;
    const obj = {
      id: newDevice.id,
      companyId: newDevice.companyId,
      blockContract: newDevice.blockContract,
      blockWallet: newDevice.blockWallet,
      trackId: newDevice.trackId,
      trackContract: newDevice.trackContract,
      trackWallet: newDevice.trackWallet,
      groupUid: newDevice.groupUid,
      uid: newDevice.uid,
      device: newDevice.device,
      typeId: this.selectedType,
      type: this.typeOptions.filter((t) => t.value === this.selectedType)[0]
        .label,
      brand: newDevice.brand,
      model: newDevice.model,
      info: newDevice.info,
      emissoin_type_unit: newDevice.emissoin_type_unit
    };

    updateDevice(this.token, obj, (err, data) => {
      if (data) {
        this._fetchUnits();
        toast.success("Save Successfully!");
      } else {
        toast.error(err.toString());
      }
    });
  };

  fetchAlgorithmParam = () => {
    getAlgorithmParam(this.token, (err, data) => {
      if (data) {
        if (data.message === "Token Expired!") {
          toast.info(data.message);
          window.location.pathname = "/";
        }
        runInAction(() => {
          const algoOpt = data.map((v, k) => {
            return {
              value: v.id,
              label: v.name,
            };
          });
          this.algorithmParam = data;
          this.algorithmParamOptions = algoOpt;
          this.selectedAlgorithm = algoOpt[0].value;
        });
      } else {
        toast.error(err.toString());
      }
    });
  };

  _handleSelectDevice = (data) => {
    this.selectedDeviceData = data;
    this.newEditData = data;
  };

  _handleSelectType = (e, tableIndex) => {
    this.selectedType = e.value;
  };

  _handleTextChange = (index, key, subKey, e) => {
    const importText = e.target.value;

    if (key === "info") {
      this.newEditData[key][subKey] = importText;
    } else {
      this.newEditData[key] = importText;
    }
  };

  fetchAssetType = () => {
    getAllAssetType(this.companyId, this.token, (err, data) => {
      if (data) {
        runInAction(() => {
          // const assOpt = data.map((v, k) => {
          //   return {
          //     value: v.id,
          //     label: v.name,
          //   };
          // });
          // this.typeOptions = assOpt;
          this.selectedType = data.asset_type;
        });
      } else {
        toast.error(err.toString());
      }
    });
  };

  fetchScheduleList = () => {
    getAllScheduleList(this.token, (err, scheduleList) => {
      if (scheduleList) {
        runInAction(() => {
          const schOpt = scheduleList.map((v, k) => {
            return {
              value: v.id,
              label: v.schedule,
            };
          });
          this.scheduleListOptions = schOpt;
          this.selectedSchedule = schOpt[0].value;
        });
      } else {
        toast.error(err.toString());
      }
    });
  };

  _fetchUnits = async (props) => {
    this.deviceList = [];
    await getUnits(
      this.token,
      this.currentAssets,
      this.companyId,
      (err, data) => {
        if (data) {
          if (data.message === "Token Expired!") {
            toast.info(data.message);
            window.location.pathname = "/";
          }
          runInAction(() => {
            this.deviceList = data;
          });
        } else {
          toast.error(err.toString());
        }
      }
    );
  };

  //   fetchAllFMSDevice = async (props) => {
  //     this.deviceList = [];
  //     await getAllFMSDevice(this.token, this.currentAssets, this.companyId, (err, data) => {
  //       if (data) {
  //         if (data.message === "Token Expired!") {
  //           toast.info(data.message);
  //           window.location.pathname = '/';
  //         }
  //         runInAction(() => {
  //           this.deviceList = data;
  //         });
  //       } else {
  //         toast.error(err.toString());
  //       }
  //     })
  //   }

  //   fetchAllDeviceList = async (props) => {
  //     const data_ = Store.common.isAds
  //       ? { companyId: this.companyId, siteId: 14 }
  //       : { companyId: this.companyId, asset: this.currentAssets };

  //     await getAllDevice(this.token, data_, (err, data) => {
  //       if (data) {
  //         if (data.message === "Token Expired!") {
  //           toast.info(data.message);
  //           window.location.pathname = '/';
  //         }
  //         runInAction(() => {
  //           this.deviceList = data;
  //         });
  //       } else {
  //         toast.error(err.toString());
  //       }
  //     });
  //   };

  _handleUploadImage = (e) => {
    // this.formData.append('file', e.target.files[0])
    this.deviceImage = e.target.files[0]
      ? URL.createObjectURL(e.target?.files[0])
      : "";
    this.photo = e.target.files[0];
    this.deviceData.file = e.target.files[0];
    this.newUpload = true;

    // const photoUrl = baseURL + '/uploads/devices/' + this.deviceData.image
    // this.urlToObject(photoUrl)
    // const formData = new FormData();
    //     e.preventDefault();
    //     formData.append('file',this.photo)
  };

  _handleTextChange = (e, field) => {
    switch (field) {
      case "file":
        this.deviceData.file = e.target.files[0];
        this.deviceImage = e.target.files[0]
          ? URL.createObjectURL(e.target?.files[0])
          : "";
        break;
      case "vehicle_sub_type":
        this.deviceData.vehicle_sub_type = e.target.value;
        break;
      case "vehicle_number_plate":
        this.deviceData.vehicle_number_plate = e.target.value;
        break;
      case "carrying_capacity":
        this.deviceData.carrying_capacity = e.target.value;
        break;
      case "brand":
        this.deviceData.brand = e.target.value;
        break;
      case "oem_baseline_emission":
        this.deviceData.oem_baseline_emission = e.target.value;
        break;
      case "vin_number":
        this.deviceData.vin_number = e.target.value;
        break;
      case "primary_fuel_type":
        this.deviceData.fuel_type = e.value;
        this.validateError = null;
        break;
      case "secondary_fuel_type":
        this.deviceData.secondary_fuel_type = e.target.value;
        break;
      case "battery_type":
        this.deviceData.battery_type = e.target.value;
        break;
      case "fuel_tank_capacity":
        this.deviceData.fuel_tank_capacity = e.target.value;
        break;
      case "cargo_type":
        this.deviceData.cargo_type = e.target.value;
        break;
      case "carrying_capacity":
        this.deviceData.carrying_capacity = e.target.value;
        break;
      case "color":
        this.deviceData.color = e.target.value;
        break;
      case "width":
        this.deviceData.width = e.target.value;
        break;
      case "gross_weight":
        this.deviceData.gross_weight = e.target.value;
        break;
      case "height":
        this.deviceData.height = e.target.value;
        break;
      case "battery_capacity":
        this.deviceData.battery_capacity = e.target.value;
        break;
      case "model":
        this.deviceData.model = e.target.value;
        break;
      case "oem_baseline_emission":
        this.deviceData.oem_baseline_emission = e.target.value;
        break;
      case "cargo_type":
        this.deviceData.cargo_type = e.target.value;
        break;
      case "asset_type":
        this.deviceData.asset_type = e.value;
        this.validateError = null;
        break;
      case "asset_description":
        this.deviceData.vehicle_type = e.target.value;
        this.validateError = null;
        break;
      case "emission_type_unit":
        this.deviceData.emission_type_unit = e.value;
        break;
    }

    
    this.disableAddVehicle =
      this.deviceData.vehicle_number_plate &&
        this.deviceData.asset_type &&
        this.deviceData.brand &&
        this.deviceData.model &&
        this.deviceData.vin_number &&
        this.deviceData.oem_baseline_emission
        ? false
        : true;
    
  };

  _handleMinMaxChange = (e, field) => {

    const value = e.target.value;
    if (value.length > 5) {
      this.validateError = true;
      return;
    } else {
      this.validateError = false;
    }
    switch (field) {
      case "daily_fuel_consumed_min":
        this.deviceMinMaxData.daily_fuel_consumed_min = e.target.value;
        break;
      case "daily_fuel_consumed_max":
        this.deviceMinMaxData.daily_fuel_consumed_max = e.target.value;
        break;
      case "monthly_fuel_consumed_min":
        this.deviceMinMaxData.monthly_fuel_consumed_min = e.target.value;
        break;
      case "monthly_fuel_consumed_max":
        this.deviceMinMaxData.monthly_fuel_consumed_max = e.target.value;
        break;
      case "daily_battery_consumed_min":
        this.deviceMinMaxData.daily_battery_consumed_min = e.target.value;
        break;
      case "daily_battery_consumed_max":
        this.deviceMinMaxData.daily_battery_consumed_max = e.target.value;
        break;
      case "monthly_battery_consumed_min":
        this.deviceMinMaxData.monthly_battery_consumed_min = e.target.value;
        break;
      case "monthly_battery_consumed_max":
        this.deviceMinMaxData.monthly_battery_consumed_max = e.target.value;
        break;
      case "daily_mileage_min":
        this.deviceMinMaxData.daily_mileage_min = e.target.value;
        break;
      case "daily_mileage_max":
        this.deviceMinMaxData.daily_mileage_max = e.target.value;
        break;
      case "monthly_mileage_min":
        this.deviceMinMaxData.monthly_mileage_min = e.target.value;
        break;
      case "monthly_mileage_max":
        this.deviceMinMaxData.monthly_mileage_max = e.target.value;
        break;
      case "speed_min":
        this.deviceMinMaxData.speed_min = e.target.value;
        break;
      case "speed_max":
        this.deviceMinMaxData.speed_max = e.target.value;
        break;
    }
    this.disableAddMinMaxVehicle =
      this.deviceMinMaxData.fuel_consumed_min &&
        this.deviceMinMaxData.fuel_consumed_max &&
        this.deviceMinMaxData.battery_consumed_min &&
        this.deviceMinMaxData.battery_consumed_max &&
        this.deviceMinMaxData.mileage_min &&
        this.deviceMinMaxData.mileage_max &&
        this.deviceMinMaxData.speed_min &&
        this.deviceMinMaxData.speed_max
        ? false
        : true;
  };

  setEditMode = (value) => {
    this.editMode = value;
  };

  setEditMinMaxMode = (value) => {
    this.editMinMaxMode = value;
  };

  _handleEdit = (row) => {
    this.setEditMode(true);

    const selectedUnit = this.deviceList.filter(
      (unit) => unit.grouping === row.grouping
    )[0];

    this.deviceImage = selectedUnit.image
      ? `${baseIMGURL}uploads/devices/${selectedUnit.image}`
      : null;
    this.deviceData = {
      uid: selectedUnit.id,
      brand: selectedUnit.brand,
      cargo_type: selectedUnit.cargo_type,
      vehicle_number_plate: selectedUnit.plate_number,
      model: selectedUnit.model,
      oem_baseline_emission: selectedUnit.manual_baseline_emission_km,
      primary_fuel_type: selectedUnit.primary_fuel_type,
      vin_number: selectedUnit.vin_number,
      asset_type: selectedUnit.asset_type,
      vehicle_type: selectedUnit.vehicle_type,
      battery_capacity: selectedUnit.battery_capacity,
      image: selectedUnit.image,
      secondary_fuel_type: selectedUnit.secondary_fuel_type,
      fuel_type: selectedUnit.fuel_type,
      fuel_tank_capacity: selectedUnit.fuel_tank_capacity,
      carrying_capacity: selectedUnit.carrying_capacity,
      height: selectedUnit.vehicle_high,
      width: selectedUnit.vehicle_width,
      gross_weight: selectedUnit.gross_vehicle_weight,
      battery_type: selectedUnit.battery_type || "",
      emission_type_unit: selectedUnit?.emissionTypeUnit,
    };
  };


  _handleEditMinMax = async (row) => {
    try {
      if (!row || !row.grouping) {
        console.log('Row object or grouping property is missing.');
        return;
      }
      console.log("row grouping ===>", row.grouping);

      const selectedUnit = this.deviceList?.find((unit) => unit?.grouping === row.grouping);
      console.log('selectedUnit ===>', selectedUnit);

      if (selectedUnit) {
        if (selectedUnit.deviceMinMaxData) {
          console.log('this.deviceMinMaxData is already present:', selectedUnit.deviceMinMaxData);
          this.setEditMinMaxMode(true);
          this.deviceMinMaxData = { ...selectedUnit.deviceMinMaxData };
        } else {
          if (selectedUnit.min_max_id !== undefined) {
            const unitId = selectedUnit.id;
            const token = this.token;

            try {
              const response = await getDeviceThreshold(unitId, token);


              if (response.payload) {
                const data = response.payload;

                this.deviceMinMaxData = {
                  uid: selectedUnit.id,
                  fuel_type: selectedUnit.fuel_type,
                  grouping: data.grouping,
                  id: data.id,
                  daily_fuel_consumed_min: data.fuel_consumed_min,
                  daily_fuel_consumed_max: data.fuel_consumed_max,
                  daily_battery_consumed_min: data.battery_consumed_min,
                  daily_battery_consumed_max: data.battery_consumed_max,
                  monthly_fuel_consumed_min: data.monthly_fuel_consumed_min,
                  monthly_fuel_consumed_max: data.monthly_fuel_consumed_max,
                  monthly_battery_consumed_min: data.monthly_battery_consumed_min,
                  monthly_battery_consumed_max: data.monthly_battery_consumed_max,
                  daily_mileage_min: data.mileage_min,
                  daily_mileage_max: data.mileage_max,
                  monthly_mileage_min: data.monthly_mileage_min,
                  monthly_mileage_max: data.monthly_mileage_max,
                  speed_min: data.speed_min,
                  speed_max: data.speed_max
                };

                this.setEditMinMaxMode(true);
              } else {
                console.log('API request failed or response is undefined.');
              }
            } catch (err) {
              console.log('An error occurred:', err);
              throw err;
            }
          } else {
            console.log('min_max_id is not available for the selected unit');
          }
        }
      } else {
        console.log('No matching unit found for the given grouping:', row.grouping);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };





  // const selectedUnit = this.deviceList?.filter(
  //   (unit) => unit?.grouping === row?.grouping
  // )[0];
  //   console.log("selecteduint====>",selectedUnit)
  // selectedUnit?.min_max_id ? this.setEditMinMaxMode(true):this.setEditMinMaxMode(false);
  //   this.deviceMinMaxData = {
  //     uid: selectedUnit?.id,
  //     fuel_type: selectedUnit?.fuel_type,
  //     fuel_consumed_min: selectedUnit?.fuel_consumed_min,
  //     fuel_consumed_max: selectedUnit?.fuel_consumed_max,
  //     battery_consumed_min: selectedUnit?.battery_consumed_min,
  //     battery_consumed_max: selectedUnit?.battery_consumed_max,
  // mileage_min: selectedUnit?.mileage_min,
  // mileage_max: selectedUnit?.mileage_max,
  // speed_min: selectedUnit?.speed_min,
  // speed_max: selectedUnit?.speed_max
  //   };
  // };

  urlToObject = async (image) => {
    var imageFile = "";
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    this.photo = file;
    this.deviceData.file = file;
  };

  _handleAddNewDevice = async (e, props) => {
    const photoUrl =
      this.deviceData.image && this.deviceData.image !== ""
        ? baseURL + "/uploads/devices/" + this.deviceData.image
        : null;

    const formData = new FormData();
    e.preventDefault();

    if (!this.newUpload) {
      this.urlToObject(photoUrl).then(async (data) => {
        this.deviceData.file = this.photo;
        const ValidatedErr = AssetFormValidation({
          asset_type: this.deviceData.asset_type,
          fuel_type: this.deviceData.fuel_type,
          brand: this.deviceData.brand,
          battery_type: this.deviceData.battery_type,
          vehicle_number_plate: this.deviceData.vehicle_number_plate,
          oem_baseline_emission: this.deviceData.oem_baseline_emission,
          model: this.deviceData.model,
          vin_number: this.deviceData.vin_number,
        });
        this.err = ValidatedErr;

        
        // if (ValidatedErr.cargo_type) {
        //   document.getElementById(cargo_type).focus();
        //   toast.error(this.deviceData.cargo_type);
        // }
        if (Object.keys(ValidatedErr).length !== 0) {
          this.validateError = ValidatedErr;
        } else {
          this.deviceData.primary_fuel_type = this.deviceData.fuel_type;

          for (const key in this.deviceData) {
            if (this.deviceData.hasOwnProperty(key)) {
              formData.append(key, this.deviceData[key]);
            }
          }

          formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
          });

          this.editMode
            ? await editDeviceAPI(
              this.token,
              this.companyId,
              formData,
              (err, data) => {
                if (data) {
                  if (data.message === "Token Expired!") {
                    toast.info(data.message);
                  }
                  if (data.message === "Update Successful!") {
                    toast.success(data.message);
                    this.deviceData = {};
                    this._fetchUnits();
                    window.location.reload();
                    this.deleteMode = false;
                  }
                } else {
                  console.log(err);
                  toast.error(err.toString());
                }
              }
            )
            : await addNewDevice(
              this.token,
              this.companyId,
              formData,
              (err, data) => {
                if (data) {
                  if (data.message === "Token Expired!") {
                    toast.info(data.message);
                  }
                  if (data.message === "Register successful") {
                    toast.success(data.message);
                    this.deviceData = {};
                    this._fetchUnits();
                    window.location.reload();
                  }
                } else {
                  toast.error(err.toString());
                }
              }
            );
        }
      });
    } else {
      const ValidatedErr = AssetFormValidation({
        asset_type: this.deviceData.asset_type,
        fuel_type: this.deviceData.fuel_type,
        brand: this.deviceData.brand,
        battery_type: this.deviceData.battery_type,
        vehicle_number_plate: this.deviceData.vehicle_number_plate,
        oem_baseline_emission: this.deviceData.oem_baseline_emission,
        model: this.deviceData.model,
        vin_number: this.deviceData.vin_number,
      });
      this.err = ValidatedErr;

      // if (ValidatedErr.cargo_type) {
      //   document.getElementById(cargo_type).focus();
      //   toast.error(this.deviceData.cargo_type);
      // }
      if (Object.keys(ValidatedErr).length !== 0) {
        this.validateError = ValidatedErr;
      } else {
        this.deviceData.primary_fuel_type = this.deviceData.fuel_type;

        for (const key in this.deviceData) {
          if (this.deviceData.hasOwnProperty(key)) {
            formData.append(key, this.deviceData[key]);
          }
        }

        this.editMode
          ? await editDeviceAPI(
            this.token,
            this.companyId,
            formData,
            (err, data) => {
              if (data) {
                if (data.message === "Token Expired!") {
                  toast.info(data.message);
                }
                if (data.message === "Update Successful!") {
                  toast.success(data.message);
                  this.deviceData = {};
                  this._fetchUnits();
                  window.location.reload();
                  this.deleteMode = false;
                }
              } else {
                toast.error(err.toString());
              }
            }
          )
          : await addNewDevice(
            this.token,
            this.companyId,
            formData,
            (err, data) => {
              if (data) {
                if (data.message === "Token Expired!") {
                  toast.info(data.message);
                }
                if (data.message === "Register successful") {
                  toast.success(data.message);
                  this.deviceData = {};
                  this._fetchUnits();
                  window.location.reload();
                }
              } else {
                toast.error(err.toString());
              }
            }
          );
      }
    }
  };

  // _handleAddNewMinMaxDevice = async (e, props) => {
  //   const formData = new FormData();
  //   e.preventDefault();
  //       for (const key in this.deviceMinMaxData) {
  //         if (this.deviceMinMaxData.hasOwnProperty(key)) {
  //           formData.append(key, this.deviceMinMaxData[key]);
  //         }
  //       }

  //       await editMinMaxDeviceAPI(
  //             this.token,
  //             this.companyId,
  //             formData,
  //             (err, data) => {
  //               if (data) {
  //                 if (data.message === "Token Expired!") {
  //                   toast.info(data.message);
  //                 }
  //                 if (data.message === "Update Successful!") {
  //                   toast.success(data.message);
  //                   this.deviceMinMaxData = {};
  //                   this._fetchUnits();
  //                   window.location.reload();
  //                   this.deleteMode = false;
  //                 }
  //               } else {
  //                 toast.error(err.toString());
  //               }
  //             }
  //           )

  // };

  _handleAddNewMinMaxDevice = async (e, props) => {
    e.preventDefault();

    if (!this.deviceMinMaxData) {
      console.error("this.deviceMinMaxData is undefined");
      return;
    }

    console.log("++++> deviceminmaxdata ===>", toJS(this.deviceMinMaxData))

    const fuelConsumedMin = parseInt(this.deviceMinMaxData.daily_fuel_consumed_min, 10);
    const fuelConsumedMax = parseInt(this.deviceMinMaxData.daily_fuel_consumed_max, 10);
    const batteryConsumedMin = parseInt(this.deviceMinMaxData.daily_battery_consumed_min, 10);
    const batteryConsumedMax = parseInt(this.deviceMinMaxData.daily_battery_consumed_max, 10);
    const mileageMin = parseInt(this.deviceMinMaxData.daily_mileage_min, 10);
    const mileageMax = parseInt(this.deviceMinMaxData.daily_mileage_max, 10);
    const speedMin = parseInt(this.deviceMinMaxData.speed_min, 10);
    const speedMax = parseInt(this.deviceMinMaxData.speed_max, 10);

    const monthlyFuelConsumedMin = parseInt(this.deviceMinMaxData.monthly_fuel_consumed_min, 10);
    const monthlyFuelConsumedMax = parseInt(this.deviceMinMaxData.monthly_fuel_consumed_max, 10);
    const monthlyBatteryConsumedMin = parseInt(this.deviceMinMaxData.monthly_battery_consumed_min, 10);
    const monthlyBatteryConsumedMax = parseInt(this.deviceMinMaxData.monthly_battery_consumed_max, 10);
    const monthlyMileageMin = parseInt(this.deviceMinMaxData.monthly_mileage_min, 10);
    const monthlyMileageMax = parseInt(this.deviceMinMaxData.monthly_mileage_max, 10);

    if (
      fuelConsumedMax < fuelConsumedMin ||
      batteryConsumedMax < batteryConsumedMin ||
      mileageMax < mileageMin ||
      speedMax < speedMin
    ) {
      toast.error("Maximum values cannot be less than minimum values", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const updatedData = {
      fuel_consumed_min: fuelConsumedMin,
      fuel_consumed_max: fuelConsumedMax,
      battery_consumed_min: batteryConsumedMin,
      battery_consumed_max: batteryConsumedMax,
      mileage_min: mileageMin,
      mileage_max: mileageMax,
      speed_min: speedMin,
      speed_max: speedMax,
      monthly_fuel_consumed_min: monthlyFuelConsumedMin,
      monthly_fuel_consumed_max: monthlyFuelConsumedMax,
      monthly_battery_consumed_min: monthlyBatteryConsumedMin,
      monthly_battery_consumed_max: monthlyBatteryConsumedMax,
      monthly_mileage_min: monthlyMileageMin,
      monthly_mileage_max: monthlyMileageMax,
      id: this.deviceMinMaxData.id,
    };

    console.log("updatedData ====>", updatedData)
    try {
      const response = await editDeviceMinMaxThreshold(this.token, updatedData);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Updated Successfull!");
        const closeButton = document.querySelector("#addVehicleMinMaxModalJQ [data-bs-dismiss='modal']");
        if (closeButton) {
          closeButton.click();
        }
        this.deviceMinMaxData = {};
        this._fetchUnits();
        this.deleteMode = false;
      }
    } catch (err) {
      toast.error(err.toString());
    }
  };





  _setDeleteMode = (value) => {
    this.deleteMode = value;
  };

  _setToDelete = (row) => {
    // const selectedUnit = this.deviceList.filter(
    //   (unit) => unit.plate_number ? unit.plate_number : unit.grouping === row.plate_number ? row.plate_number : row.grouping
    // )[0];

    const selectedUnit = this.deviceList.find(
      (unit) => unit.id === row.id
    )

    this.toDelete = { uid: selectedUnit?.id, license_plate: row.plate_number === "N/A" ? row.grouping : row.plate_number };
  };

  _handleRemoveDevice = async () => {
    await removeDevice(
      this.toDelete.uid,
      this.companyId,
      this.token,
      (err, data) => {
        if (data) {
          if (data.message === "Token Expired!") {
            toast.info(data.message);
          } else {
            toast.success(data.message);
            this._fetchUnits();
            this.deleteMode = false;
          }
        } else {
          toast.error(err.toString());
        }
      }
    );
  };

  _handleCloseModel = () => {
    this.deviceData = {};
  };

  _handleCloseMinMaxModel = () => {
    this.deviceMinMaxData = {};
  };

  _fetchLvlCompanies() {
    getLvlCompanies(Common.token, this.companyId, (err, data) => {
      if (data) {
        this.lvlCompanies = data;
      } else {
        toast.error(err);
      }
    });
  }

  setGpsChecked(value) {
    this.gpsChecked = value;
  }

  setCanbusChecked(value) {
    this.canbusChecked = value;
  }

  setSearchedUnits(value) {
    console.log("value", value);
    this.searchedUnits = value;
    console.log("===>", this.searchedUnits);
  }

  _handleSearchChange(e, value) {
    this.searchValue = true;
    console.log(this.searchValue);
    this.searchKey = e.target.value;
    this.searchedUnits = value.filter((v, i) =>
      v.company
        .toLowerCase()
        .toString()
        .includes(this.searchKey.toLowerCase().toString())
    );
  }

  _handleSelectAssetType(value) {
    this.selectedAssetType = value;
    console.log("selectedAssetType", this.selectedAssetType);
  }

  _handleSelectedDetail(value, index) {
    runInAction(() => {
      this.selectedDetail = value;
      this.selectedIndex = index;
    });
  }

  _handleSetUnitData(value) {
    this.unitData = value;
  }
}

export default new AssetProfile();
