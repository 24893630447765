import { inject, observer } from "mobx-react";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { emission_vehicle } from "../../Assets/Config/constant.config";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { CarbonEmissionManualChart } from "./CarbonEmissionManualChart";
import { CarbonEmissionLiveChart } from "./CarbonEmissionLiveChart";
import { CarbonChart } from "./CarbonChart";
import { CustomCarbonChart } from "./CustomCarbonChart";

export const Profile = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { cardStyle1, companyType } = props;

    const {
      assetProfile,
      vehicleType,
      showChartView,
      showChartViewVTwo,
      unitSetting,
    } = props.store.trackingVTwo;

    const { historicalStatus } = props.store.trackAndTrace;

    const theme = localStorage.getItem("theme");

    const isLoading = !assetProfile;

    const rowStyle = {
      marginRight: 5,
      minHeight: 30,
      borderBottom: `1px solid rgba(100,100,100,0.2)`,
    };

    const totalCarbonEmission = assetProfile
      ? (Number(assetProfile.fuelConsumed) * emission_vehicle) / 1000
      : 0;

    const totalTripMileage = assetProfile
      ? Number(assetProfile.vehicleMileage)
      : 0;

    const emissionFactor = (totalCarbonEmission / totalTripMileage) * 1000000;

    //const type = "EV";

    return (
      <div
        style={cardStyle1}
        className="d-flex flex-row flex-wrap justify-content-center"
      >
        {isLoading && <CO2_Loading text={t("Loading...")} />}
        {!isLoading && (
          <>
            <div
              className="profile-unitLevel-guide col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-2 "
              style={{
                borderRight:
                  window.innerWidth < 701
                    ? ""
                    : theme === "dark"
                    ? "1px solid " + Colors.Dark[4]
                    : "1px solid black",
                borderBottom:
                  window.innerWidth < 701
                    ? theme === "dark"
                      ? "1px solid " + Colors.Dark[4]
                      : "1px solid black"
                    : "",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-3">{t("Equipment Profile")}</h5>

                <div
                  className="type-unitLevel-guide d-flex justify-content-between align-items-center mb-3"
                  style={{ width: "165px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>CANBus</span>
                    <span className="ps-1 pe-1">:</span>
                    {unitSetting?.enableCANbus ? (
                      <span>
                        <i
                          className="fa fa-check pe-1"
                          style={{ color: "rgb(0, 177, 0)" }}
                        ></i>
                      </span>
                    ) : (
                      <span>
                        <i
                          className="fas fa-times pe-1"
                          style={{ color: "red" }}
                        ></i>
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>GPS</span>
                    <span className="ps-1 pe-1">:</span>
                    {unitSetting?.enableGPS ? (
                      <span>
                        <i
                          className="fa fa-check pe-1"
                          style={{ color: "rgb(0, 177, 0)" }}
                        ></i>
                      </span>
                    ) : (
                      <span>
                        <i
                          className="fas fa-times pe-1"
                          style={{ color: "red" }}
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>{t("Unit Owner")}</span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.unitOwner || "--"}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>{t("Vehicle Type")}</span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.vehicleType || "--"}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Vehicle Sub Type")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.vehicleSubType === "undefined"
                    ? "N/A"
                    : assetProfile.vehicleSubType}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>{t("Brand")}</span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.brand || "--"}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>{t("Model")}</span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.model || "--"}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Carrying Capacity (tonnes)")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.carryingCapacity === "undefined"
                    ? "N/A"
                    : Number(assetProfile.carryingCapacity).toLocaleString(
                        "en-US"
                      )}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Gross Vehicle Weight (tonnes)")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.grossVehicleWeight == null
                    ? "--"
                    : Number(assetProfile.grossVehicleWeight).toLocaleString(
                        "en-US"
                      )}
                </span>
              </div>

              {vehicleType.label === "EV" ? (
                <>
                  <div
                    className="d-flex justify-content-between align-items-center px-2 mx-0"
                    style={rowStyle}
                  >
                    <span style={{ textAlign: "left" }}>
                      {t("Battery Type")}
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {assetProfile.battery_type || "--"}
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center px-2 mx-0"
                    style={rowStyle}
                  >
                    <span style={{ textAlign: "left" }}>
                      {t("Battery Capacity")} (kWh).
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {assetProfile.battery_capacity || "--"}
                    </span>
                  </div>

                  {(unitSetting?.enableCANbus && !unitSetting?.enableGPS) ||
                  (unitSetting?.enableGPS && !unitSetting?.enableCANbus) ? (
                    <>
                      {companyType === "1" ? (
                        <div
                          className="d-flex justify-content-between align-items-center px-2 mx-0"
                          style={rowStyle}
                        >
                          <span style={{ textAlign: "left" }}>
                            {t("Vehicle Mileage")} (km)
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {assetProfile?.live_manual?.vehicleMileage == null
                              ? "0.00"
                              : Number(
                                  assetProfile?.live_manual?.vehicleMileage
                                ).toLocaleString("en-US")}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="d-flex justify-content-between align-items-center px-2 mx-0"
                        style={rowStyle}
                      >
                        <span style={{ textAlign: "left" }}>
                          {t("Total Fuel Consumption")} (L)
                        </span>
                        <span style={{ textAlign: "right" }}>
                          {assetProfile?.live_manual?.totalConsumed == null
                            ? "0.00"
                            : Number(
                                assetProfile?.live_manual?.totalConsumed
                              ).toLocaleString("en-US")}
                        </span>
                      </div>
                    </>
                  ) : unitSetting?.enableGPS && unitSetting?.enableCANbus ? (
                    <>
                      {companyType === "1" ? (
                        <div
                          className="d-flex justify-content-between align-items-center px-2 mx-0"
                          style={rowStyle}
                        >
                          <span style={{ textAlign: "left" }}>
                            {t("Vehicle Mileage")} (km)
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {assetProfile?.live?.vehicleMileage == null
                              ? "0.00"
                              : Number(
                                  assetProfile?.live?.vehicleMileage
                                ).toLocaleString("en-US")}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="d-flex justify-content-between align-items-center px-2 mx-0"
                        style={rowStyle}
                      >
                        <span style={{ textAlign: "left" }}>
                          {t("Total Fuel Consumption")} (L)
                        </span>
                        <span style={{ textAlign: "right" }}>
                          {assetProfile?.live?.totalConsumed == null
                            ? "0.00"
                            : Number(
                                assetProfile?.live?.totalConsumed
                              ).toLocaleString("en-US")}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {companyType === "1" ? (
                        <div
                          className="d-flex justify-content-between align-items-center px-2 mx-0"
                          style={rowStyle}
                        >
                          <span style={{ textAlign: "left" }}>
                            {t("Vehicle Mileage")} (km)
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {assetProfile?.manual?.vehicleMileage == null
                              ? "0.00"
                              : Number(
                                  assetProfile?.manual?.vehicleMileage
                                ).toLocaleString("en-US")}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="d-flex justify-content-between align-items-center px-2 mx-0"
                        style={rowStyle}
                      >
                        <span style={{ textAlign: "left" }}>
                          {t("Total Fuel Consumption")} (L)
                        </span>
                        <span style={{ textAlign: "right" }}>
                          {assetProfile?.manual?.totalConsumed == null
                            ? "0.00"
                            : Number(
                                assetProfile?.manual?.totalConsumed
                              ).toLocaleString("en-US")}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-between align-items-center px-2 mx-0"
                    style={rowStyle}
                  >
                    <span style={{ textAlign: "left" }}>
                      {t("Primary Fuel Type")}
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {assetProfile.primaryFuelType || "--"}
                    </span>
                  </div>

                  <div
                    className="d-flex justify-content-between align-items-center px-2 mx-0"
                    style={rowStyle}
                  >
                    <span style={{ textAlign: "left" }}>
                      {t("Secondary Fuel Type")}
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {assetProfile.secondaryFuelType || "--"}
                    </span>
                  </div>

                  {showChartViewVTwo === "CANBus" ? (
                    <>
                      {companyType === "1" ? (
                        <div
                          className="d-flex justify-content-between align-items-center px-2 mx-0"
                          style={rowStyle}
                        >
                          <span style={{ textAlign: "left" }}>
                            {t("Vehicle Mileage")} (km)
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {assetProfile?.live?.vehicleMileage == null
                              ? "0.00"
                              : Number(
                                  assetProfile?.live?.vehicleMileage
                                ).toLocaleString("en-US")}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="d-flex justify-content-between align-items-center px-2 mx-0"
                        style={rowStyle}
                      >
                        <span style={{ textAlign: "left" }}>
                          {t("Total Fuel Consumption")} (L)
                        </span>
                        <span style={{ textAlign: "right" }}>
                          {assetProfile?.live?.totalConsumed == null
                            ? "0.00"
                            : Number(
                                assetProfile?.live?.totalConsumed
                              ).toLocaleString("en-US")}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {companyType === "1" ? (
                        <div
                          className="d-flex justify-content-between align-items-center px-2 mx-0"
                          style={rowStyle}
                        >
                          <span style={{ textAlign: "left" }}>
                            {t("Vehicle Mileage")} (km)
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {assetProfile?.manual?.vehicleMileage == null
                              ? "0.00"
                              : Number(
                                  assetProfile?.manual?.vehicleMileage
                                ).toLocaleString("en-US")}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="d-flex justify-content-between align-items-center px-2 mx-0"
                        style={rowStyle}
                      >
                        <span style={{ textAlign: "left" }}>
                          {t("Total Fuel Consumption")} (L)
                        </span>
                        <span style={{ textAlign: "right" }}>
                          {assetProfile?.manual?.totalConsumed == null
                            ? "0.00"
                            : Number(
                                assetProfile?.manual?.totalConsumed
                              ).toLocaleString("en-US")}
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Total Idling Duration")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.totalIdlingDuration == null
                    ? "00hours 00mins 00secs"
                    : assetProfile.totalIdlingDuration}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Total In-Motion Duration")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.totalInMotionDuration == null
                    ? "00hours 00mins 00secs"
                    : assetProfile.totalInMotionDuration}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Harsh-Braking Instances")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.harshBreakingInstances || "0"}
                </span>
              </div>

              <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              >
                <span style={{ textAlign: "left" }}>
                  {t("Harsh-Acceleration Instances")}
                </span>
                <span style={{ textAlign: "right" }}>
                  {assetProfile.harshAccelerationInstances || "0"}
                </span>
              </div>

              {/* <div
                className="d-flex justify-content-between align-items-center px-2 mx-0"
                style={rowStyle}
              > 
                 <span style={{ textAlign: "left" }}>
                  //  {t("OEM Baseline Emission Factor")} (gCO₂e/km){" "} 
                   {t("Baseline Emission Factor")} (gCO₂e/km){" "} 
                   <i
                    className="fas fa-info-circle"
                    title="For the first [year] of data collection, the baseline emission factor would be based on the OEM’s technical specification of the vehicle, expressed in gCO2e/km."
                    style={{
                      cursor: "pointer",
                    }}
                  /> 
                 </span> 
                 <span style={{ textAlign: "right" }}> 
                   {assetProfile?.manualBaselineEmissionPerKm || "--"} 
                 </span>
              </div>  */}
              <>
                <div
                  className="justify-content-between align-items-center px-2 mx-0"
                  style={rowStyle}
                >
                  <div className="d-flex justify-content-between">
                    <span style={{ textAlign: "left" }}>
                      {/* {t("Historical Baseline Emission Factor")} (gCO₂e/km){" "} */}
                      {t("Baseline Emission Factor")} ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}){" "}
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {assetProfile?.baseline_emission_factor || "--"}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "0.98rem" }}>
                      ({t("Baseline Type")} - {assetProfile?.emission_type})
                    </span>
                  </div>
                </div>
              </>
            </div>
            <div className="chart-unitLevel-guide col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-5 px-3">
              {/* {assetProfile.carbonEmissionsByMonth?.length > 0 ? ( */}
              {/* {showChartViewVTwo === "CANBus" ? (
                <CarbonEmissionLiveChart theme={theme} />
              ) : (
                <CarbonEmissionManualChart theme={theme} />
              )} */}
              {/* <CarbonChart
                assetProfile={assetProfile}
                theme={theme}
                chartView={showChartView}
              /> */}
              <CustomCarbonChart
                assetProfile={assetProfile}
                theme={theme}
                chartView={showChartView}
              />
              {/* ) : (
                t("No Data")
              )} */}
            </div>
          </>
        )}
      </div>
    );
  })
);
