import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import { My_Button as MyButton } from "../../Tools";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Cookies } from "react-cookie";
import {
  emission_ev_vehicle,
  emission_vehicle,
} from "../../Assets/Config/constant.config";
import evercommLogo from "../../Assets/Images/icons/evercomm-logo.png";
import co2Logo from "../../Assets/Images/CO2_Logo.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { LiveModalSection } from "./LiveModalSection";
import { ManualModalSection } from "./ManualModalSection";
import { DetailModalSection } from "./DetailModalSection";
import autoTable from "jspdf-autotable";
import moment from "moment";
import user from "../../helper/localStorage";
import { baseURL } from "../../network/URL";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { LiveManualModalSection } from "./LiveManualModalSection";
import Tour from "./unitLevelTour";
import { Modal } from "react-bootstrap";

export const TrackingFileExportModal = inject("store")(
  observer((props) => {
    // const { theme } = props;
    const { t } = useTranslation("new-tracking");

    const theme = "dark"; // for consistent printing

    const liveRef = React.useRef(null);
    const manualRef = React.useRef(null);
    const liveManualRef = React.useRef(null);
    const detailRef = React.useRef(null);
    const modalRef = useRef();

    const cookie = new Cookies();
    const cName = cookie.get("companyName");
    const {
      handleViewChange,
      selectedVehicle,
      assetProfile,
      month,
      year,
      vehicleType,
      trackingDetailVTwo,
      showChartView,
      showChartViewVTwo,
      startDateFormat,
      endDateFormat,
      dateFormat,
      yearSelected,
      unitSetting,
      setReportDownload,
      setShowTooltip,
    } = props.store.trackingVTwo;

    const { companyName } = props.store.common;
    const [selecetedFile, setSelectedFile] = useState("pdf");
    const { showView } = props;

    const modalElement = modalRef.current;

    useEffect(() => {
      const modalElement = modalRef.current;

      const handleHidden = () => {
        setReportDownload(false);
        setShowTooltip(true);
      };

      if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", handleHidden);

        return () => {
          modalElement.removeEventListener("hidden.bs.modal", handleHidden);
        };
      }
    }, []);

    const showCompanyName = cName || companyName;
    useEffect(() => {
      // fetchFMSVehicles();

      handleViewChange("chart");
    }, []);
    const site = user.getSelectedSite();
    let imgUrl =
      props.store.common.isAds || site.logoUrl
        ? site.logoUrl
        : props.store.common.role === "super_admin"
        ? props.store.dashboard.selectedLogo
        : props.store.common.logo;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const emission =
      vehicleType.value === "EV" ? emission_ev_vehicle : emission_vehicle;

    //FOr excel data

    let modifyTrackingDetail = trackingDetailVTwo
      ? trackingDetailVTwo?.map((v) => ({
          Driver: v.driver === "" ? "N/A" : v.driver,
          Date: moment.utc(v.start_time).format("yyyy-MM-DD"),
          Start: v.start,
          End: v.end,
          "Trip Mileage(km)": v.trip_mileage,
          "Carbon Emission(kgCO2e)": v.emission,
          "Emission Intensity(gCO2e/km)": v.emissionFactor
            ? v.emissionFactor.toFixed(2)
            : v.emissionFactor === null
            ? "0.00"
            : v.emissionFactor === "null"
            ? "0.00"
            : "0.00",
        }))
      : [];

    var liveData = assetProfile?.live?.carbonEmission?.map((v) => ({
      [dateFormat === "Year" ? "Month" : "Date"]:
        dateFormat === "Year" && months ? months[v.month - 1] : v.days_months,
      "Emission Factor(gCO2e/km)": v.emissionFactor
        ? v.emissionFactor.toFixed(2)
        : v.emissionFactor === null
        ? "0.00"
        : v.emissionFactor === "null"
        ? "0.00"
        : "0.00",
      "Carbon Emission(kgCO2e)": v.totalEmission ? v.totalEmission.toFixed(2) : "0.00",
      "Baseline Emission(kgCO2e)": v.totalBaselineEmission,
      // "Historical Baseline Emission(kgCO2e)": v.historicalBaselineEmission,
    }));

    var manualData = assetProfile?.manual?.carbonEmission?.map((v) => ({
      [dateFormat === "Year" ? "Month" : "Date"]:
        dateFormat === "Year" && months ? months[v.month - 1] : v.days_months,
      "Emission Factor(gCO2e/km)": v.emissionFactor
        ? v.emissionFactor.toFixed(2)
        : v.emissionFactor === null
        ? "0.00"
        : v.emissionFactor === "null"
        ? "0.00"
        : "0.00",
      "Carbon Emission(kgCO2e)": v.totalEmission ? v.totalEmission.toFixed(2) : "0.00",
      "Baseline Emission(kgCO2e)": v.totalBaselineEmission,
      // "Historical Baseline Emission(kgCO2e)": v.historicalBaselineEmission,
    }));

    var liveManualData = assetProfile?.live_manual?.carbonEmission?.map(
      (v) => ({
        [dateFormat === "Year" ? "Month" : "Date"]:
          dateFormat === "Year" && months ? months[v.month - 1] : v.days_months,
        "Emission Factor(gCO2e/km)": v.emissionFactor
          ? v.emissionFactor.toFixed(2)
          : v.emissionFactor === null
          ? "0.00"
          : v.emissionFactor === "null"
          ? "0.00"
          : "0.00",
        "Carbon Emission(kgCO2e)": v.totalEmission ? v.totalEmission.toFixed(2) : "0.00",
        "Baseline Emission(kgCO2e)": v.totalBaselineEmission,
        // "Historical Baseline Emission(kgCO2e)": v.historicalBaselineEmission,
      })
    );

    // console.log(toJS(data), data1);
    /* make the worksheet */

    var wsforLive = liveData
      ? assetProfile && XLSX.utils.json_to_sheet(liveData)
      : "";
    var wsforManual = manualData
      ? assetProfile && XLSX.utils.json_to_sheet(manualData)
      : "";
    var wsforLiveManual = liveManualData
      ? assetProfile && XLSX.utils.json_to_sheet(liveManualData)
      : "";
    var detailData = modifyTrackingDetail
      ? assetProfile && XLSX.utils.json_to_sheet(modifyTrackingDetail)
      : "";

    /* write workbook (use type 'binary') */
    var csvforLive = XLSX.utils.sheet_to_csv(wsforLive);
    var csvforManual = XLSX.utils.sheet_to_csv(wsforManual);
    var csvforLiveManual = XLSX.utils.sheet_to_csv(wsforLiveManual);
    var csvforDetail = XLSX.utils.sheet_to_csv(detailData);

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    const download = async () => {
      const liveElement = liveRef.current;
      const manualElement = manualRef.current;
      const liveManualElement = liveManualRef.current;
      const detailElement = detailRef.current;

      var img = new Image();
      img.src = "/CO2_Logo.png";
      if (detailElement) {
        const doc = new jsPDF("l", undefined, "a4");
        const canvas1 = await html2canvas(detailElement);
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        const dailyData = canvas1.toDataURL("image/png");

        doc.addImage(dailyData, "JPEG", 15, 20, width - 30, 70);
        doc.text(
          15,
          15,
          `Report ${
            dateFormat === "Month"
              ? `for ${dayjs()
                  .month(month - 1)
                  .format("MMMM")}-${yearSelected}`
              : dateFormat === "Year"
              ? `for ${yearSelected}`
              : dateFormat === "7 Days" || dateFormat === "Custom"
              ? `from  ${startDateFormat} to ${endDateFormat}`
              : ""
          }\n${showCompanyName}`
        );
        autoTable(doc, {
          styles: { fontSize: 8 },
          startY: 90,
          // columnStyles: { start: { halign: 'center', colSpan: 2 } }, // start centered
          body: modifyTrackingDetail,
          columns: [
            { header: t("Driver"), dataKey: "Driver" },
            { header: t("Date"), dataKey: "Date" },
            { header: t("Start Point"), dataKey: "Start" },
            { header: t("End Point"), dataKey: "End" },
            { header: t("Mileage") + " (km)", dataKey: "Trip Mileage(km)" },
            {
              header: t("CO2e Emission") + " (kgCO2e)",
              dataKey: "Carbon Emission(kgCO2e)",
            },
            {
              header: t("Emission Intensity") + "(gCO2e/km)",
              dataKey: "Emission Intensity(gCO2e/km)",
            },
          ],
        });
        doc.save(
          selectedVehicle?.label +
            `_${
              dateFormat === "Month"
                ? `${dayjs()
                    .month(month - 1)
                    .format("MMMM")}_${yearSelected}`
                : dateFormat === "Year"
                ? `${yearSelected}`
                : dateFormat === "7 Days" || dateFormat === "Custom"
                ? `from  ${startDateFormat} to ${endDateFormat}`
                : ""
            }` +
            "_Detail"
        );
      } else if (liveElement) {
        try {
          const pdf = new jsPDF("p", undefined, "a4");
          let width = pdf.internal.pageSize.getWidth();
          let height = pdf.internal.pageSize.getHeight();

          pdf.saveGraphicsState();
          pdf.setGState(new pdf.GState({ opacity: 0.7 }));
          // pdf.addImage(`${baseURL + imgUrl}`, "png", 20, 7, 20, 20);
          // pdf.addImage(co2Logo, "png", width - 40, 10, 25, 15);
          pdf.addImage(`${baseURL + imgUrl}`, "JPEG", 20, 7, 20, 20);
          pdf.addImage(co2Logo, "JPEG", width - 40, 10, 25, 15);
          pdf.restoreGraphicsState();
          pdf.setFontSize(24);
          pdf.setTextColor(10, 88, 202);
          // pdf.text(20, 45, "CO2 Emission Tracking & Computation Dashboard");
          // pdf.text(
          //   `Report for ${year}, ${months[month - 1]}\n${showCompanyName}`,
          //   width / 2,
          //   145,
          //   "center"
          // );
          pdf.text("Emission Report", width / 2, 110, "center");
          pdf.setDrawColor(65, 122, 206);
          pdf.line(15, 117, width - 15, 117);
          pdf.setFontSize(18);

          pdf.text(`For  ${showCompanyName}`, width / 2, 130, "center");
          pdf.setFontSize(14);
          pdf.setTextColor(0, 0, 0, 0.6);

          pdf.text(
            `${
              dateFormat === "Month"
                ? ` ${dayjs()
                    .month(month - 1)
                    .format("MMMM")}-${yearSelected}`
                : dateFormat === "Year"
                ? ` ${yearSelected}`
                : dateFormat === "7 Days" || dateFormat === "Custom"
                ? `from  ${startDateFormat} to ${endDateFormat}`
                : ""
            }`,
            width / 2,
            140,
            "center"
          );
          pdf.setDrawColor(0, 0, 0, 0.6);
          pdf.line(30, height - 7, width - 30, height - 7);
          pdf.setFontSize(12);
          pdf.text("1 | Page", width - 30, height - 3, "right");

          if (liveElement) {
            const canvas1 = await html2canvas(liveElement);

            const liveData = canvas1.toDataURL("image/png");

            pdf.addPage();
            pdf.addImage(
              liveData,
              "PNG",
              0,
              0,
              width,
              assetProfile?.live?.carbonEmission?.length > 15
                ? height
                : height / 1.5
            );
            pdf.setDrawColor(0, 0, 0, 0.6);
            pdf.line(30, height - 7, width - 30, height - 7);
            pdf.setFontSize(12);
            pdf.text("2 | Page", width - 30, height - 3, "right");
          } else if (manualElement) {
            const canvas2 = await html2canvas(manualElement);
            const manualData = canvas2.toDataURL("image/png");

            pdf.addPage();
            pdf.addImage(
              manualData,
              "PNG",
              0,
              0,
              width,
              assetProfile?.manual?.carbonEmission?.length > 15
                ? height
                : height / 1.5
            );
            pdf.setDrawColor(0, 0, 0, 0.6);
            pdf.line(30, height - 7, width - 30, height - 7);
            pdf.setFontSize(12);
            pdf.text("2 | Page", width - 30, height - 3, "right");
          } else {
            const canvas2 = await html2canvas(liveManualElement);
            const liveManualData = canvas2.toDataURL("image/png");

            pdf.addPage();
            pdf.addImage(
              liveManualData,
              "PNG",
              0,
              0,
              width,
              assetProfile?.live_manual?.carbonEmission?.length > 15
                ? height
                : height / 1.5
            );
            pdf.setDrawColor(0, 0, 0, 0.6);
            pdf.line(30, height - 7, width - 30, height - 7);
            pdf.setFontSize(12);
            pdf.text("2 | Page", width - 30, height - 3, "right");
          }
          pdf.save(
            selectedVehicle?.label +
              "_" +
              `${
                dateFormat === "Month"
                  ? ` ${dayjs()
                      .month(month - 1)
                      .format("MMMM")}_${yearSelected}`
                  : dateFormat === "Year"
                  ? ` ${yearSelected}`
                  : dateFormat === "7 Days" || dateFormat === "Custom"
                  ? `from  ${startDateFormat} to ${endDateFormat}`
                  : ""
              }`
          );
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (selecetedFile === "pdf") {
      //if pdf report
      return (
        <div
          className="modal fade overflow-scroll"
          id="newtrackingPdfModal"
          tabIndex="-1"
          aria-labelledby="newtrackingPdfModal"
          ref={modalRef}
          onHide={() => {
            setReportDownload(false);
            setShowTooltip(true);
          }}
        >
          <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">
            {/* {<Tour/>} */}
            <div
              className="download-unitLevel-guide row me-2 d-flex align-items-center"
              style={{
                height: 40,
                color: Colors.Dark[5],
                borderRadius: 5,
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => setSelectedFile("pdf")}
                className="col h-100 d-flex align-items-center"
                style={{
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  background: Colors.darkBlue,
                  opacity: selecetedFile !== "pdf" && 0.5,
                }}
              >
                PDF
              </div>
              <div
                onClick={() => setSelectedFile("xlsx")}
                className="col h-100 d-flex align-items-center"
                style={{
                  background: Colors.darkBlue,
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  opacity: selecetedFile !== "xlsx" && 0.5,
                }}
              >
                {t("Excel")}
              </div>
            </div>
            <MyButton
              leftIcon={<DownloadIcon className="me-2" fontSize="small" />}
              small={false}
              className="px-3 me-3"
              text={t("Download")}
              onClick={() => {
                download();
              }}
            />
            <MyButton
              leftIcon={<CloseIcon />}
              small={false}
              className="close rounded-circle"
              dataDismiss="modal"
              onClick={() => {
                setReportDownload(false);
                setShowTooltip(true);
              }}
            />
          </div>
          {/* fixed width for printing certificate */}
          <div className="modal-dialog modal-xl rounded">
            {showView === "chart" ? (
              <div
                className="modal-content text-center p-3"
                style={{
                  color: Colors.Dark[1],
                }}
              >
                <CoverPage
                  imgUrl={imgUrl}
                  showChartViewVTwo={showChartViewVTwo}
                  showCompanyName={showCompanyName}
                  year={year}
                  month={month}
                  startDateFormat={startDateFormat}
                  endDateFormat={endDateFormat}
                  dateFormat={dateFormat}
                  yearSelected={yearSelected}
                />

                {unitSetting?.enableCANbus && unitSetting?.enableGPS ? (
                  <LiveModalSection
                    ref_key={liveRef}
                    companyName={showCompanyName}
                    months={months}
                    emission={emission}
                    assetProfile={assetProfile}
                    vehicleType={vehicleType}
                    emissionData={assetProfile?.live}
                  />
                ) : !unitSetting?.enableCANbus && !unitSetting?.enableGPS ? (
                  <LiveModalSection
                    ref_key={liveRef}
                    companyName={showCompanyName}
                    months={months}
                    emission={emission}
                    assetProfile={assetProfile}
                    vehicleType={vehicleType}
                    emissionData={assetProfile?.manual}
                  />
                ) : (
                  <LiveModalSection
                    ref_key={liveRef}
                    companyName={showCompanyName}
                    months={months}
                    emission={emission}
                    assetProfile={assetProfile}
                    vehicleType={vehicleType}
                    emissionData={assetProfile?.live_manual}
                  />
                  //   <LiveManualModalSection
                  //     liveManualRef={liveManualRef}
                  //     companyName={showCompanyName}
                  //     months={months}
                  //     emission={emission}
                  //     assetProfile={assetProfile}
                  //     vehicleType={vehicleType}
                  //   />
                )}
              </div>
            ) : (
              <div
                className="modal-content text-center p-3"
                style={{
                  color: Colors.Dark[1],
                }}
              >
                <DetailModalSection
                  detailRef={detailRef}
                  companyName={showCompanyName}
                  months={months}
                  emission={emission}
                  selectedVehicle={selectedVehicle}
                  assetProfile={assetProfile}
                  vehicleType={vehicleType}
                  trackingDetailVTwo={trackingDetailVTwo}
                />
              </div>
            )}
          </div>
        </div>
      );
    } //if excel report
    else
      return (
        <div
          className="modal fade overflow-scroll"
          id="newtrackingPdfModal"
          tabIndex="-1"
          aria-labelledby="newtrackingPdfModal"
          aria-hidden="true"
        >
          <div className="w-100 d-flex align-items-center justify-content-end py-2 px-3">
            {/* {<Tour/>} */}
            <div
              className="row me-2 d-flex align-items-center"
              style={{
                height: 40,
                color: Colors.Dark[5],
                borderRadius: 5,
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => setSelectedFile("pdf")}
                className="col h-100 d-flex align-items-center"
                style={{
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  background: Colors.darkBlue,
                  opacity: selecetedFile !== "pdf" && 0.5,
                }}
              >
                PDF
              </div>
              <div
                onClick={() => setSelectedFile("xlsx")}
                className="col h-100 d-flex align-items-center"
                style={{
                  background: Colors.darkBlue,
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  opacity: selecetedFile !== "xlsx" && 0.5,
                }}
              >
                {t("Excel")}
              </div>
            </div>

            <MyButton
              leftIcon={<DownloadIcon className="me-2" fontSize="small" />}
              small={false}
              className="px-3 me-3"
              text={t("Download")}
              onClick={() => {
                if (showView === "chart") {
                  if (unitSetting?.enableCANbus && unitSetting?.enableGPS) {
                    FileSaver.saveAs(
                      new Blob(
                        [
                          ["Company Name", showCompanyName],
                          ["\nVehicle Plate Number", selectedVehicle?.label],
                          ["\nVehicle Type", vehicleType?.label || "--"],
                          ["\nBrand", assetProfile?.brand || "--"],
                          ["\nModel", assetProfile?.model || "--"],
                          [
                            "\nTotal Baseline Emission",
                            assetProfile?.live?.totalBaselineEmission.toFixed(
                              4
                            ) || "--",
                            " kgCO2e",
                          ],
                          // [
                          //   "\nTotal Historical Baseline Emission",
                          //   assetProfile?.live?.totalHistoricalBaselineEmission.toFixed(
                          //     4
                          //   ) || "--",
                          //   " kgCO2e",
                          // ],
                          [
                            "\nTotal Trip Mileage",
                            assetProfile?.live?.vehicleMileage || "--",
                            " km",
                          ],
                          [
                            "\nTotal Consumption",
                            assetProfile?.live?.totalConsumed == null
                              ? "--"
                              : assetProfile?.live?.totalConsumed,
                            vehicleType?.label === "EV" ? "kWh" : "L",
                          ],
                          [
                            "\nBaseline Emission Factor",
                            assetProfile?.baseline_emission_factor || "--",
                            `${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`,
                          ],
                          [
                            "\nBaseline Type",
                            assetProfile?.baseline_emission_type,
                          ],

                          `\n\nLive Report ${
                            dateFormat === "Month"
                              ? `for ${dayjs()
                                  .month(month - 1)
                                  .format("MMMM")}-${yearSelected}`
                              : dateFormat === "Year"
                              ? `for ${yearSelected}`
                              : dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                              ? `from  ${startDateFormat} to ${endDateFormat}`
                              : ""
                          }\n`,
                          s2ab(csvforLive),
                        ],
                        { type: "application/octet-stream" }
                      ),
                      `${selectedVehicle?.label}_${
                        dateFormat === "Month"
                          ? `${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          : dateFormat === "Year"
                          ? `${yearSelected}`
                          : dateFormat === "7 Days" || dateFormat === "Custom"
                          ? `from  ${startDateFormat} to ${endDateFormat}`
                          : ""
                      }_Live.csv`
                    );
                  } else if (
                    !unitSetting?.enableCANbus &&
                    !unitSetting?.enableGPS
                  ) {
                    FileSaver.saveAs(
                      new Blob(
                        [
                          ["Company Name", showCompanyName],
                          ["\nVehicle Plate Number", selectedVehicle?.label],
                          ["\nVehicle Type", vehicleType?.label || "--"],
                          ["\nBrand", assetProfile?.brand || "--"],
                          ["\nModel", assetProfile?.model || "--"],
                          [
                            "\nTotal Baseline Emission",
                            assetProfile?.manual?.totalBaselineEmission.toFixed(
                              4
                            ) || "--",
                            " kgCO2e",
                          ],
                          // [
                          //   "\nTotal Historical Baseline Emission",
                          //   assetProfile?.manual?.totalHistoricalBaselineEmission.toFixed(
                          //     4
                          //   ) || "--",
                          //   " kgCO2e",
                          // ],
                          [
                            "\nTotal Trip Mileage",
                            assetProfile?.manual?.vehicleMileage || "--",
                            " km",
                          ],
                          [
                            "\nTotal Consumption",
                            assetProfile?.manual?.totalConsumed == null
                              ? "--"
                              : assetProfile?.manual?.totalConsumed,
                            vehicleType?.label === "EV" ? "kWh" : "L",
                          ],
                          [
                            "\nBaseline Emission Factor",
                            assetProfile?.baseline_emission_factor || "--",
                            `${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`,
                          ],
                          [
                            "\nBaseline Type",
                            assetProfile?.baseline_emission_type,
                          ],
                          `\n\nManual Report ${
                            dateFormat === "Month"
                              ? `for ${dayjs()
                                  .month(month - 1)
                                  .format("MMMM")}-${yearSelected}`
                              : dateFormat === "Year"
                              ? `for ${yearSelected}`
                              : dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                              ? `from  ${startDateFormat} to ${endDateFormat}`
                              : ""
                          }\n`,
                          s2ab(csvforManual),
                        ],
                        { type: "application/octet-stream" }
                      ),
                      `${selectedVehicle?.label}_${
                        dateFormat === "Month"
                          ? `${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          : dateFormat === "Year"
                          ? `${yearSelected}`
                          : dateFormat === "7 Days" || dateFormat === "Custom"
                          ? `from  ${startDateFormat} to ${endDateFormat}`
                          : ""
                      }_Manual.csv`
                    );
                  } else {
                    FileSaver.saveAs(
                      new Blob(
                        [
                          ["Company Name", showCompanyName],
                          ["\nVehicle Plate Number", selectedVehicle?.label],
                          ["\nVehicle Type", vehicleType?.label || "--"],
                          ["\nBrand", assetProfile?.brand || "--"],
                          ["\nModel", assetProfile?.model || "--"],
                          [
                            "\nTotal Baseline Emission",
                            assetProfile?.live_manual?.totalBaselineEmission.toFixed(
                              4
                            ) || "--",
                            " kgCO2e",
                          ],
                          // [
                          //   "\nTotal Historical Baseline Emission",
                          //   assetProfile?.live_manual?.totalHistoricalBaselineEmission.toFixed(
                          //     4
                          //   ) || "--",
                          //   " kgCO2e",
                          // ],
                          [
                            "\nTotal Trip Mileage",
                            assetProfile?.live_manual?.vehicleMileage || "--",
                            " km",
                          ],
                          [
                            "\nTotal Consumption",
                            assetProfile?.live_manual?.totalConsumed == null
                              ? "--"
                              : assetProfile?.live_manual?.totalConsumed,
                            vehicleType?.label === "EV" ? "kWh" : "L",
                          ],
                          [
                            "\nBaseline Emission Factor",
                            assetProfile?.baseline_emission_factor || "--",
                            `${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`,
                          ],
                          [
                            "\nBaseline Type",
                            assetProfile?.baseline_emission_type,
                          ],

                          `\n\nManual Report ${
                            dateFormat === "Month"
                              ? `for ${dayjs()
                                  .month(month - 1)
                                  .format("MMMM")}-${yearSelected}`
                              : dateFormat === "Year"
                              ? `for ${yearSelected}`
                              : dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                              ? `from  ${startDateFormat} to ${endDateFormat}`
                              : ""
                          }\n`,
                          s2ab(csvforLiveManual),
                        ],
                        { type: "application/octet-stream" }
                      ),
                      `${selectedVehicle?.label}_${
                        dateFormat === "Month"
                          ? `${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          : dateFormat === "Year"
                          ? `${yearSelected}`
                          : dateFormat === "7 Days" || dateFormat === "Custom"
                          ? `from  ${startDateFormat} to ${endDateFormat}`
                          : ""
                      }_LiveManual.csv`
                    );
                  }
                } else {
                  FileSaver.saveAs(
                    new Blob(
                      [
                        ["Company Name", showCompanyName],
                        ["\nVehicle Plate Number", selectedVehicle.label],
                        ["\nVehicle Type", vehicleType.label || "--"],
                        ["\nBrand", assetProfile?.brand || "--"],
                        ["\nModel", assetProfile?.model || "--"],
                        [
                          "\nTotal Baseline Emission",
                          assetProfile?.live?.totalBaselineEmission.toFixed(
                            4
                          ) || "--",
                          " kgCO2e",
                        ],
                        // [
                        //   "\nTotal Historical Baseline Emission",
                        //   assetProfile?.live?.totalHistoricalBaselineEmission.toFixed(
                        //     4
                        //   ) || "--",
                        //   " kgCO2e",
                        // ],
                        [
                          "\nTotal Trip Mileage",
                          assetProfile?.live?.vehicleMileage || "--",
                          " km",
                        ],
                        [
                          "\nTotal Consumption",
                          assetProfile?.live?.totalConsumed == null
                            ? "--"
                            : assetProfile?.live?.totalConsumed,
                          vehicleType?.label === "EV" ? "kWh" : "L",
                        ],
                        [
                          "\nBaseline Emission Factor",
                          assetProfile?.baseline_emission_factor || "--",
                          `${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}`,
                        ],
                        [
                          "\nBaseline Type",
                          assetProfile?.baseline_emission_type,
                        ],
                        [
                          `\n\nDetail Report ${
                            dateFormat === "Month"
                              ? `${dayjs()
                                  .month(month - 1)
                                  .format("MMMM")}_${yearSelected}`
                              : dateFormat === "Year"
                              ? ` ${yearSelected}`
                              : dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                              ? `from  ${startDateFormat} to ${endDateFormat}`
                              : ""
                          }\n`,
                        ],

                        s2ab(csvforDetail),
                      ],
                      { type: "application/octet-stream" }
                    ),
                    `${selectedVehicle.label}_${
                      dateFormat === "Month"
                        ? `${dayjs()
                            .month(month - 1)
                            .format("MMMM")}-${yearSelected}`
                        : dateFormat === "Year"
                        ? ` ${yearSelected}`
                        : dateFormat === "7 Days" || dateFormat === "Custom"
                        ? `from  ${startDateFormat} to ${endDateFormat}`
                        : ""
                    }_Detail.csv`
                  );
                }
              }}
            />
            <MyButton
              leftIcon={<CloseIcon />}
              small={false}
              className="close rounded-circle"
              dataDismiss="modal"
              onClick={() => {}}
            />
          </div>
          {/* fixed width for printing certificate */}
          <div className="modal-dialog modal-xl rounded">
            <div
              className="modal-content p-3"
              style={{
                color: Colors.Dark[1],
                // border: `6px solid ${Colors.lightBlue}`,
                // background:
                //   theme === "dark" ? Colors.dark.background : Colors.Dark[5],
              }}
            >
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                {t("Company")}: {showCompanyName}
              </span>
              {/* <div style={{ fontSize: 20, fontWeight: "bold" }}>License Plate:  {selectedVehicle?.label}</div> */}
              <div
                style={{ color: Colors.Dark[0], fontSize: 14 }}
                className="row col-5 py-3"
              >
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Vehicle Plate Number")}
                </span>
                <span className="col-6 ">: {selectedVehicle?.label}</span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Vehicle Type")}
                </span>
                <span className="col-6 ">: {vehicleType?.label || "--"}</span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Brand")}
                </span>
                <span className="col-6 ">: {assetProfile?.brand || "--"}</span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Model")}
                </span>
                <span className="col-6 ">: {assetProfile?.model || "--"}</span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Total Baseline Emission")}
                </span>
                <span className="col-6 ">
                  :{" "}
                  {showChartViewVTwo === "CANBus"
                    ? assetProfile?.live?.totalBaselineEmission
                      ? `${assetProfile?.live?.totalBaselineEmission.toFixed(
                          4
                        )} kgCO₂e`
                      : "--"
                    : showChartViewVTwo === "manual"
                    ? assetProfile?.manual?.totalBaselineEmission
                      ? `${assetProfile?.manual?.totalBaselineEmission.toFixed(
                          4
                        )} kgCO₂e`
                      : "--"
                    : assetProfile?.trackingDetailVTwo?.totalBaselineEmission
                    ? `${assetProfile?.trackingDetailVTwo?.totalBaselineEmission.toFixed(
                        4
                      )} kgCO₂e`
                    : "--"}
                </span>
                {/* <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Total Historical Baseline Emission")}
                </span>
                <span className="col-6 ">
                  :{" "}
                  {showChartViewVTwo === "CANBus"
                    ? assetProfile?.live?.totalHistoricalBaselineEmission
                      ? `${assetProfile?.live?.totalHistoricalBaselineEmission.toFixed(
                          4
                        )} kgCO₂e`
                      : "--"
                    : showChartViewVTwo === "manual"
                    ? assetProfile?.manual?.totalHistoricalBaselineEmission
                      ? `${assetProfile?.manual?.totalHistoricalBaselineEmission.toFixed(
                          4
                        )} kgCO₂e`
                      : "--"
                    : assetProfile?.trackingDetailVTwo?.totalBaselineEmission
                    ? `${assetProfile?.trackingDetailVTwo?.totalBaselineEmission.toFixed(
                        4
                      )} kgCO₂e`
                    : "--"}
                </span> */}
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Total Trip Mileage")}
                </span>
                <span className="col-6 ">
                  :{" "}
                  {showChartViewVTwo === "CANBus"
                    ? assetProfile?.live?.vehicleMileage + " km" || "--"
                    : assetProfile?.manual?.vehicleMileage + " km" || "--"}
                </span>

                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Total Consumption")}
                </span>
                <span className="col-6 ">
                  :{" "}
                  {showChartViewVTwo === "CANBus"
                    ? assetProfile?.live?.totalConsumed
                      ? Number(
                          assetProfile?.live?.totalConsumed
                        ).toLocaleString("en-US")
                      : assetProfile?.live?.totalConsumed == null
                      ? "--"
                      : "--"
                    : assetProfile?.manual?.totalConsumed == null
                    ? "--"
                    : Number(
                        assetProfile?.manual?.totalConsumed
                      ).toLocaleString("en-US")}
                  {vehicleType?.label === "EV" ? " kWh" : " L"}
                </span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Baseline Emission Factor")}
                </span>
                <span className="col-6 ">
                  :{" "}
                  {assetProfile?.baseline_emission_factor + ` ${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}` ||
                    "--"}
                </span>
                <span style={{ fontWeight: "bold" }} className="col-6 ">
                  {t("Baseline Type")}
                </span>
                <span className="col-6 ">
                  : {assetProfile?.baseline_emission_type}
                </span>
              </div>
              {showView === "chart" ? (
                <div className="p-3">
                  {showChartViewVTwo === "CANBus" ? (
                    <div>
                      <span className=" text-black fw-bold ">
                        {t("Live Report ")}
                        {dateFormat === "Month"
                          ? `for ${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          : dateFormat === "Year"
                          ? `for ${yearSelected}`
                          : dateFormat === "7 Days" || dateFormat === "Custom"
                          ? `from  ${startDateFormat} to ${endDateFormat}`
                          : ""}
                      </span>
                      {assetProfile?.live?.carbonEmission?.length > 0 ? (
                        <table
                          style={{
                            width: "100%",
                            border: `1px solid ${Colors.Dark[4]}`,
                          }}
                        >
                          <tr>
                            <th
                              className=" ps-2"
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                              }}
                            >
                              {dateFormat === "Month" ||
                              dateFormat === "7 Days" ||
                              dateFormat === "Custom"
                                ? t("Date")
                                : dateFormat === "Year"
                                ? t("Month")
                                : ""}
                            </th>
                            <th
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                                textAlign: "center",
                              }}
                            >
                              {t("GHG Emission Factor")}({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
                            </th>
                            <th
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                                textAlign: "center",
                              }}
                            >
                              {t("GHG Emission Factor (kgCO₂e)")}
                            </th>
                            <th
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                                textAlign: "center",
                              }}
                            >
                              {t("Baseline GHG Emission")}(kgCO₂e)
                            </th>
                            {/* <th style={{ textAlign: "center" }}>
                              {t("Historical Baseline Emission")}(kgCO₂e)
                            </th> */}
                          </tr>
                          {assetProfile?.live?.carbonEmission?.map((v) => (
                            <tr
                              style={{
                                borderTop: `1px solid ${Colors.Dark[4]}`,
                              }}
                            >
                              <th
                                align="center"
                                className=" ps-2"
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                              >
                                {dateFormat === "Month" ||
                                dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                                  ? v.days_months
                                  : dateFormat === "Year"
                                  ? months[v.month - 1]
                                  : ""}
                              </th>
                              <td
                                align="center"
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                              >
                                {v.emissionFactor
                                  ? v.emissionFactor.toFixed(4)
                                  : v.emissionFactor === null
                                  ? "0.00"
                                  : "0.00"}
                              </td>
                              <td
                                align="center"
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                              >
                                {v.totalEmission
                                  ? v.totalEmission.toFixed(4)
                                  : v.totalEmission === null
                                  ? "0.00"
                                  : "0.00"}
                              </td>
                              <td
                                align="center"
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                              >
                                {v.totalBaselineEmission
                                  ? Number(
                                      Math.abs(v.totalBaselineEmission).toFixed(
                                        4
                                      )
                                    ).toLocaleString("en-US")
                                  : v.totalBaselineEmission === null
                                  ? "0.0000"
                                  : "0.0000"}{" "}
                              </td>
                              {/* <td align="center">
                                {v.historicalBaselineEmission
                                  ? Number(
                                      Math.abs(
                                        v.historicalBaselineEmission
                                      ).toFixed(4)
                                    ).toLocaleString("en-US")
                                  : v.historicalBaselineEmission === null
                                  ? "0.0000"
                                  : "0.0000"}{" "}
                              </td> */}
                            </tr>
                          ))}
                        </table>
                      ) : (
                        <div
                          style={{ background: "rgb(0,0,0,0.04)" }}
                          className="p-2 text-center"
                        >
                          {t("No Data")}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="mt-4 text-black fw-bold">
                        {t("Manual Report ")}
                        {dateFormat === "Month"
                          ? `for ${dayjs()
                              .month(month - 1)
                              .format("MMMM")}-${yearSelected}`
                          : dateFormat === "Year"
                          ? `for ${yearSelected}`
                          : dateFormat === "7 Days" || dateFormat === "Custom"
                          ? `from  ${startDateFormat} to ${endDateFormat}`
                          : ""}
                      </div>
                      {assetProfile?.manual?.carbonEmission?.length > 0 ? (
                        <table
                          style={{
                            width: "100%",
                            border: `1px solid ${Colors.Dark[4]}`,
                          }}
                        >
                          <tr>
                            <th
                              className=" ps-2"
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                              }}
                            >
                              {dateFormat === "Month" ||
                              dateFormat === "7 Days" ||
                              dateFormat === "Custom"
                                ? "Date"
                                : dateFormat === "Year"
                                ? "Month"
                                : ""}
                            </th>
                            <th
                              align="center"
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                                textAlign: "center",
                              }}
                            >
                              {t("GHG Emission Factor")}({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
                            </th>
                            <th
                              style={{
                                borderRight: `1px solid ${Colors.Dark[4]}`,
                                textAlign: "center",
                              }}
                            >
                              {t("GHG Emission Factor")}(kgCO₂e)
                            </th>
                            <th style={{ textAlign: "center" }}>
                              {t("Baseline GHG Emission (kgCO₂e)")}
                            </th>
                          </tr>
                          {assetProfile?.manual?.carbonEmission?.map((v) => (
                            <tr
                              style={{
                                borderTop: `1px solid ${Colors.Dark[4]}`,
                              }}
                            >
                              <th
                                className=" ps-2"
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                              >
                                {dateFormat === "Month" ||
                                dateFormat === "7 Days" ||
                                dateFormat === "Custom"
                                  ? v.days_months
                                  : dateFormat === "Year"
                                  ? months[v.month - 1]
                                  : ""}
                              </th>
                              <td
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                                align="center"
                              >
                                {v.emissionFactor
                                  ? v?.emissionFactor.toFixed(4)
                                  : v.emissionFactor === null
                                  ? "0.0000"
                                  : "0.0000"}
                              </td>
                              <td
                                style={{
                                  borderRight: `1px solid ${Colors.Dark[4]}`,
                                }}
                                align="center"
                              >
                                {v.totalEmission
                                  ? v?.totalEmission.toFixed(4)
                                  : v.totalEmission === null
                                  ? "0.0000"
                                  : "0.0000"}
                              </td>
                              <td align="center">
                                {v.totalBaselineEmission
                                  ? Number(
                                      Math.abs(v.totalBaselineEmission).toFixed(
                                        4
                                      )
                                    ).toLocaleString("en-US")
                                  : v.totalBaselineEmission === null
                                  ? "0.0000"
                                  : "0.0000"}{" "}
                              </td>
                            </tr>
                          ))}
                        </table>
                      ) : (
                        <div
                          style={{ background: "rgb(0,0,0,0.04)" }}
                          className="p-2 text-center"
                        >
                          {t("No Data")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <span className=" text-black fw-bold ">
                    {t("Detail Report ")}
                    {dateFormat === "Month"
                      ? `for ${dayjs()
                          .month(month - 1)
                          .format("MMMM")}-${yearSelected}`
                      : dateFormat === "Year"
                      ? `for ${yearSelected}`
                      : dateFormat === "7 Days" || dateFormat === "Custom"
                      ? `from  ${startDateFormat} to ${endDateFormat}`
                      : ""}
                  </span>
                  <table
                    style={{
                      width: "100%",
                      border: `1px solid ${Colors.Dark[4]}`,
                    }}
                  >
                    <tr>
                      <th
                        className=" ps-2"
                        style={{
                          borderRight: `1px solid ${Colors.Dark[4]}`,
                          width: "10%",
                        }}
                      >
                        {t("Driver")}
                      </th>
                      <th
                        className=" ps-2"
                        style={{
                          borderRight: `1px solid ${Colors.Dark[4]}`,
                          width: "12%",
                        }}
                      >
                        {t("Date")}
                      </th>
                      <th
                        className="ps-2"
                        style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                      >
                        {t("Start Point")}
                      </th>
                      <th
                        className="ps-2"
                        style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                      >
                        {t("End Point")}
                      </th>
                      <th
                        style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                      >
                        {t("Mileage")} (km)
                      </th>
                      <th
                        style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                      >
                        {t("CO2e Emission")} (kgCO₂e)
                      </th>
                      <th
                        style={{ borderRight: `1px solid ${Colors.Dark[4]}` }}
                      >
                        {t("Emission Intensity")} (gCO₂e/km)
                      </th>
                    </tr>
                    {trackingDetailVTwo?.map((v) => (
                      <tr>
                        <td
                          className="ps-2"
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                        >
                          {v.driver || "N/A"}
                        </td>
                        <td
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                          align="center"
                        >
                          {moment.utc(v.start_time).format("yyyy-MM-DD")}
                        </td>
                        <td
                          className="ps-2"
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                        >
                          {v.start}
                        </td>
                        <td
                          className="ps-2"
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                        >
                          {v.end}
                        </td>
                        <td
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                          align="center"
                        >
                          {v.trip_mileage}
                        </td>
                        <td
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                          align="center"
                        >
                          {v.emission.toFixed(2)}
                        </td>
                        <td
                          style={{
                            borderRight: `1px solid ${Colors.Dark[4]}`,
                            borderTop: `1px solid ${Colors.Dark[4]}`,
                          }}
                          align="center"
                        >
                          {v.emissionFactor ? v.emissionFactor : "0.00"}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      );
  })
);

export const CoverPage = (props) => {
  const {
    imgUrl,
    showChartView,
    showCompanyName,
    year,
    month,
    showChartViewVTwo,
    startDateFormat,
    endDateFormat,
    dateFormat,
    yearSelected,
  } = props;
  const { t } = useTranslation("new-tracking");

  return (
    <div
      className="p-5"
      style={{ height: 1200, borderBottom: "1px solid gray" }}
    >
      <div className="d-flex justify-content-between">
        <img height={100} src={`${baseURL + imgUrl}`} />
        <img height={50} src={co2Logo} />
      </div>
      <div
        style={{
          paddingTop: 400,
          paddingBottom: 10,
          fontSize: 30,
          color: Colors.lightBlue,
          borderBottom: `1px solid ${Colors.lightBlue}`,
        }}
      >
        {showChartViewVTwo === "CANBus"
          ? t("Emission Report")
          : t("Emission Report")}
      </div>
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          fontSize: 25,
          color: Colors.lightBlue,
        }}
      >
        {t("For")} {showCompanyName}
      </div>

      <div style={{ fontSize: 20, color: Colors.Dark[3] }}>
        {dateFormat === "Month"
          ? `${dayjs()
              .month(month - 1)
              .format("MMMM")}-${yearSelected}`
          : dateFormat === "Year"
          ? ` ${yearSelected}`
          : dateFormat === "7 Days" || dateFormat === "Custom"
          ? `from  ${startDateFormat} to ${endDateFormat}`
          : ""}
      </div>
    </div>
  );
};
