// import { useState } from "react";
// import Select, { components } from "react-select";

// const InputOption = ({
//     getStyles,
//     Icon,
//     isDisabled,
//     isFocused,
//     isSelected,
//     children,
//     innerProps,
//     ...rest
// }) => {
//     const [isActive, setIsActive] = useState(false);
//     const onMouseDown = () => setIsActive(true);
//     const onMouseUp = () => setIsActive(false);
//     const onMouseLeave = () => setIsActive(false);

//     // styles
//     let bg = "transparent";
//     if (isFocused) bg = "#eee";
//     if (isActive) bg = "#B2D4FF";

//     const style = {
//         alignItems: "center",
//         backgroundColor: bg,
//         color: "inherit",
//         display: "flex "
//     };

//     // prop assignment
//     const props = {
//         ...innerProps,
//         onMouseDown,
//         onMouseUp,
//         onMouseLeave,
//         style
//     };

//     return (
//         <components.Option
//             {...rest}
//             isDisabled={isDisabled}
//             isFocused={isFocused}
//             isSelected={isSelected}
//             getStyles={getStyles}
//             innerProps={props}
//         >
//             <input type="checkbox" checked={isSelected} />
//             {children}
//         </components.Option>
//     );
// };


// export const CarbonSelector = (props) => {
//     const { options } = props

//     let datas = []
//     const [selectedValues, setSelectedValues] = useState([]);

//     const handleChange = (selectedOptions) => {
//         const values = selectedOptions.map((option) => option.value);
//         setSelectedValues(values);
//     };

//     console.log("selectedvalues ===>", selectedValues)

//     return (

//         <Select
//             // defaultValue={selectedValue}
//             isMulti
//             // value={selectedValue}
//             value={options.filter((option) => selectedValues.includes(option.value))}
//             closeMenuOnSelect={false}
//             hideSelectedOptions={false}
//             // placeholder={selectedValue}
//             onChange={(e) => handleChange(e)}
//             options={options}
//             components={{
//                 Option: InputOption
//             }}
//         />


//     );
// }
import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from 'react';
import { Colors } from "../../Assets/Config/color.config"
import { Dropdown, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


export const CarbonSelector = inject("store")(
    observer((props) => {

        const { width, theme } = props
        const { _handleChartCheckbox } = props.store.trackingVTwo

        const [selectedOptions, setSelectedOptions] = useState([]);
        const [showDropdown, setShowDropdown] = useState(false);

        const options = [
            { id: 0, label: 'In-motion CO₂e Emission' },
            // { id: 1, label: 'Idling CO₂e Emission' },
            { id: 1, label: 'Baseline CO₂e Emission' },
            { id: 2, label: 'Actual Emission Factor' },
            { id: 3, label: 'Baseline Emission Factor' },
        ];

        useEffect(() => {
            // Initialize all options as selected when the component mounts
            const allOptionLabels = options.map((option) => option.label);
            setSelectedOptions(allOptionLabels);
        }, []);

        const handleCheckboxChange = (option) => {
            const index = selectedOptions.indexOf(option.label);
            let updatedOptions = [...selectedOptions];

            if (index === -1) {
                updatedOptions = [...selectedOptions, option.label];
            } else {
                updatedOptions = updatedOptions.filter((label) => label !== option.label);
            }

            setSelectedOptions(updatedOptions);

            _handleChartCheckbox(option.label);
        };


        const handleDropdownToggle = () => setShowDropdown(!showDropdown);

        return (
            <Dropdown show={showDropdown} onToggle={handleDropdownToggle}>
                <Dropdown.Toggle className='text-start' variant="secondary" id="dropdown-checkbox" style={{ width: width, background: theme === 'dark' ? 'rgb(32, 43, 96)' : 'rgb(246, 246, 246)', color: theme === "light" ? "black" : 'silver' }}>
                    <div className="d-flex justify-content-between">
                        <span>{`${selectedOptions.length} selected`}</span>
                        <span><i className='fa fa-angle-down' style={{ color: theme === "light" ? Colors.Dark[4] : 'silver', fontSize: '18px', cursor: 'pointer' }} /></span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='light-dropdown-menu' style={{ width: width }}>
                    {options.map((option) => (
                        <div key={option.id} style={{ paddingLeft: '10px', borderBottom: option.id === 4 ? "" : '1px solid black' }} >
                            <label>
                                {/* {console.log("checked true or false", selectedOptions.includes(option.label))} */}
                                <Form.Check
                                    type="checkbox"
                                    id={`checkbox-${option.id}`}
                                    label={option.label}
                                    checked={selectedOptions.includes(option.label)}
                                    onChange={() => handleCheckboxChange(option)}
                                />
                            </label>
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }))


