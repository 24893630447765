/* eslint-disable react/jsx-pascal-case */
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { AssetProfileModal } from "./AssetProfileModal";
import { AssetSettingModal } from "./AssetSettingModal";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { Cookies } from "react-cookie";
import { CO2_Loading } from "../../Assets/CO2_Loading";
import { MyTable } from "../../Tools/MyTable";
import { CompanySelector } from "../Dashboard/companySelector";
import { My_Button, Selector, My_Input } from "../../Tools";
import AddVehicleIcon from "../../Assets/Images/icons/car1.png";
import { AddVehicleModal } from "./AddVehicleModal";
import { AddVehicleMinMaxModal } from "./AddVehicleMinMaxModal";
import { AddVehicleModalJQ } from "./AddVehicleModalJQ";
import { inject, observer } from "mobx-react";
import { ConfirmationModal } from "./ConfirmationModal";
import { ConfirmationModalJQ } from "./ConfirmatonModalJQ";
import AccessDeniedPage from "../../Pages/accessdenied";
import { fontStyle } from "@mui/system";
import { UnitsTable } from "./UintsTable";
import { useTranslation } from "react-i18next";
import { UnitsDataTableJQ } from "./UnitsDataTableJQ";
import Tour from './unitsTour';
import { UnitsReportPreviewModal } from "./UnitsReportPreviewModal";

const assetsTypesOption = [
    {
        value: "0",
        label: "All",
    },
    {
        value: "vehicle",
        label: "Vehicles",
    },
    {
        value: "equipment",
        label: "Equipment",
    },
    {
        value: "infrastructure",
        label: "Infrastructure",
    },
];

export const AssetProfileManagement = inject("store")(
    observer((props) => {
        const { t } = useTranslation("asset_profile_management")
        const cookies = new Cookies();
        const {
            deviceList,
            selectedDeviceData,
            typeOptions,
            selectedType,
            _handleSelectType,
            _handleTextChange,
            _handleSelectDevice,
            fetchUpdateDevice,
            currentAssets,
            _handleSelectAssets,
            _handleSelectedDetail,
            emissionTypeUnits
        } = props;
        const [tableIndex, setTableIndex] = useState(0);
        const [page, setPage] = useState(1);

        const theme = props.store.login.theme;
        const { userType, role, landingAscent } = props.store.common;
        const { addNewEnable,
            deleteMode,
            setEditMode,
            lvlCompanies,
            _fetchLvlCompanies,
            setCanbusChecked,
            setGpsChecked,
            _setToDelete,
            _handleEdit,
            gpsChecked
        } = props.store.assetProfile;

        const cardStyle1 = {
            background: Colors[theme].background,
            boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
            padding: "15px",
            borderRadius: "5px",
            marginBottom: 8,
            color: invertDark(theme),
            // cursor: "pointer",
        };

        const rows = deviceList?.map((data) => {
            let unit = {
                id: data.id,
                enableCANbus: data.unitSetting.enableCANbus,
                enableGPS: data.unitSetting.enableGPS,
                companyId: data.ownerId,
                company: data.name,
                grouping: data.grouping || "N/A",
                plate_number: data.plate_number || "N/A",
                model: data.model || "N/A",
                cargo_type: data.cargo_type || "N/A",
                fuel_type: data.fuel_type || "N/A",
                brand: data.brand || "N/A",
                vin: data.vin_number || "N/A",
                // oem: data.manual_baseline_emission_km || "N/A",
                oem: data.baselineEmission + `  ${data.emissionTypeUnit}` || "N/A",
                // historicalEmission: data.oem_history || "N/A",
                baselineEmissionType: data.baselineEmissionType || "N/A",
                automation_status: "" + data.automation_status,
                gps: "" + data.unitSetting.enableGPS,
                CAMbus: "" + data.unitSetting.enableCANbus,
                action: "",
            };
            return unit;
        });
        // console.log("deviceList====>",toJS(deviceList))

        const unitsReportModal = () => {
            let myModal = new window.bootstrap.Modal(
                document.getElementById("unitsreport")
            );
            myModal.show();
        };

        useEffect(() => {
            _fetchLvlCompanies()
        }, [])

        const key = 'companyId'

        const filteredCompanyList = [...new Map(rows.map(item => [item[key], item])).values()];

        const companyOptions = filteredCompanyList?.map((v) => ({
            value: v.companyId,
            label: v.company,
        }));

        companyOptions.unshift({ value: 'All', label: 'All' })

        const [selectedCompany, setSelectedCompany] = useState('All')

        const filteredData = selectedCompany === 'All' ? rows : rows.filter((v, i) => v.companyId === selectedCompany)
        
        const openAddVehicleModal = () => {
            let myModal = new window.bootstrap.Modal(document.getElementById('addVehicleModalJQ'))
            myModal.show()
        }

        return (
            <div className="pt-2 text-light">
                <span
                    style={{
                        fontSize: 23,
                        color:
                            theme === "dark" ? Colors["light"].background : Colors.Dark[2],
                    }}
                    className="title-units-guide"
                >
                    {t("Units")}
                    {/* <Tour/> */}
                </span>

                {userType === "co2xlite" ? (
                    <AccessDeniedPage
                        message={
                            "If you are interested in gaining access to Unit, please approach your CO2X account manager for details."
                        }
                    />
                ) : (
                    <>
                        <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
                            <div className="align-self-center">
                                <CompanySelector theme={theme} label={t("Organisation") + ": "} />
                            </div>

                            {
                                landingAscent.enableSetupUnit !== 1 ?
                                    <div>
                                        <span
                                            onClick={() => unitsReportModal()}
                                        >
                                            <My_Button
                                                // onClick={() => {
                                                //     openAddVehicleModal()
                                                //     setEditMode(false)
                                                // }}
                                                className="mb-2"
                                                // customHoverColor={
                                                //     theme === "dark" ? "#1272dd" : "#ade2ff"
                                                // }
                                                customColor={"#4c5d75"}

                                                style={{
                                                    cursor:
                                                        "pointer",
                                                }}
                                                // leftIcon={
                                                //     <img
                                                //         className="pe-1"
                                                //         src={AddVehicleIcon}
                                                //         height="20px"
                                                //     ></img>
                                                // }
                                                text={
                                                    <span style={{ color: "white" }}>
                                                        {t("Create Report")}
                                                    </span>
                                                }
                                            />
                                        </span>
                                    </div>
                                    :
                                    ""
                            }

                            {/* <div
                                className="ps-0"
                                style={{ width: 300, color: invertDark(theme) }}
                            >
                                <Selector
                                    menuListColor="black"
                                    isSearchable={false}
                                    label="Assets Type"
                                    options={assetsTypesOption}
                                    // value={yearOption[selectedYear]}
                                    value={
                                        assetsTypesOption.filter((v) => {
                                            return v.value === currentAssets;
                                        })[0]
                                    }
                                    _handleSelect={(e) => {
                                        _handleSelectAssets(e.value, props);
                                    }}
                                />
                            </div> */}
                        </div>

                        {
                            landingAscent.enableSetupUnit === 1 ? (
                                currentAssets === "0" ? (

                                    <div className="row justify-content-between units-pd">

                                        <div className="col-md-6 d-flex justify-content-between mt-4">
                                            <div className='d-flex'>
                                                <div>
                                                    <span
                                                        className="me-2 "
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target={
                                                    //     "#addVehicleModal"
                                                    // }
                                                    // data-toggle="modal"
                                                    // data-target=".bd-example-modal-lg"
                                                    // style={{}}
                                                    >
                                                        <My_Button
                                                            onClick={() => {
                                                                openAddVehicleModal()
                                                                setEditMode(false)
                                                            }}
                                                            className="mb-2"
                                                            // customHoverColor={
                                                            //     theme === "dark" ? "#1272dd" : "#ade2ff"
                                                            // }
                                                            customColor={"#4c5d75"}
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            leftIcon={
                                                                <img
                                                                    className="pe-1"
                                                                    src={AddVehicleIcon}
                                                                    height="20px"
                                                                ></img>
                                                            }
                                                            text={
                                                                <span style={{ color: "white" }}>
                                                                    {t("Add New Vehicle")}
                                                                </span>
                                                            }
                                                        />
                                                    </span>
                                                </div>

                                                <div>
                                                    <span
                                                        onClick={() => unitsReportModal()}
                                                    >
                                                        <My_Button
                                                            // onClick={() => {
                                                            //     openAddVehicleModal()
                                                            //     setEditMode(false)
                                                            // }}
                                                            className="mb-2 "
                                                            // customHoverColor={
                                                            //     theme === "dark" ? "#1272dd" : "#ade2ff"
                                                            // }
                                                            customColor={"#4c5d75"}

                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            // leftIcon={
                                                            //     <img
                                                            //         className="pe-1"
                                                            //         src={AddVehicleIcon}
                                                            //         height="20px"
                                                            //     ></img>
                                                            // }
                                                            text={
                                                                <span style={{ color: "white" }}>
                                                                    {t("Create Report")}
                                                                </span>
                                                            }
                                                        />
                                                    </span>
                                                </div>


                                            </div>

                                        </div>

                                        <div className="col-md-6 d-flex flex-column justify-content-end row-pd" >

                                            {/* Don't Delete if Original UnitTable open this is necessary */}
                                            <div className="d-flex flex-column flex-md-row ">
                                                <div className="mb-3 mb-md-0 mx-md-4" style={{  width: 300,  color: invertDark(theme) }}>
                                                    <Selector
                                                        menuListColor='black'
                                                        label="Company"
                                                        options={companyOptions}
                                                        _handleSelect={(e) => {
                                                            setPage(1)
                                                            setSelectedCompany(e.value)
                                                        }}
                                                        value={companyOptions.find(v => v.value === selectedCompany)}
                                                    />
                                                </div>

                                                <div className="mb-3"  style={{ width: 300, color: invertDark(theme) }}>
                                                    <Selector
                                                    
                                                        menuListColor="black"
                                                        isSearchable={false}
                                                        label={t("Assets Type")}
                                                        options={assetsTypesOption}
                                                        value={assetsTypesOption.find(v => v.value === currentAssets)}
                                                        _handleSelect={(e) => {
                                                            _handleSelectAssets(e.value, props);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>)
                                    : (
                                        <div className="d-flex justify-content-between">
                                            <span
                                                className="me-2 mt-3"
                                                // data-bs-toggle="modal"
                                                // data-bs-target={
                                                //     addNewEnable === true ? "#addVehicleModal" : ""
                                                // }
                                                // data-toggle="modal"
                                                // data-target=".bd-example-modal-lg"
                                                onClick={addNewEnable === true ? openAddVehicleModal : ''}
                                                style={{}}
                                            >
                                                <My_Button
                                                    onClick={() => setEditMode(false)}
                                                    // className="mb-2"
                                                    customHoverColor={theme === "dark" ? "#1272dd" : "#ade2ff"}
                                                    customColor={theme === "dark" ? "#202b60" : "#ffff"}
                                                    style={{
                                                        border: "1px solid #5E5E5E",
                                                        cursor: addNewEnable === true ? "pointer" : "not-allowed",
                                                    }}
                                                    leftIcon={
                                                        <img
                                                            className="pe-1"
                                                            src={AddVehicleIcon}
                                                            height="20px"
                                                        ></img>
                                                    }
                                                    text={
                                                        <span style={{ color: invertDark(theme) }}>
                                                            {currentAssets === "equipment"
                                                                ? t("Add New Equipment")
                                                                : currentAssets === "infrastructure"
                                                                    ? t("Add New Infrastructure")
                                                                    : t("Add New Vehicle")}
                                                        </span>
                                                    }
                                                />
                                            </span>
                                            {/* <div style={{ width: 300, color: invertDark(theme) }}>
                                                <Selector menuListColor='black' options={companyOptions} _handleSelect={(e) => {
                                                    setPage(1)
                                                    setSelectedCompany(e.value)
                                                }} value={companyOptions.find(v => v.value === selectedCompany)} />
                                            </div> */}


                                            <div className="d-flex justify-content-between">

                                                {/* Don't Delete if Original UnitTable open this is necessary */}
                                                <div className="mx-4 pb-3"
                                                    style={{ width: 300, color: invertDark(theme) }}>
                                                    <Selector
                                                        menuListColor='black'
                                                        label="Company"
                                                        // style = {{marginBottom: 4}}
                                                        options={companyOptions}
                                                        _handleSelect={(e) => {
                                                            setPage(1)
                                                            setSelectedCompany(e.value)
                                                        }}
                                                        value={companyOptions.find(v => v.value === selectedCompany)}
                                                    />
                                                </div>

                                                <div style={{ width: 300, color: invertDark(theme) }} className="pb-3">
                                                    <Selector
                                                        menuListColor="black"
                                                        isSearchable={false}
                                                        label={t("Assets Type")}
                                                        options={assetsTypesOption}
                                                        // value={yearOption[selectedYear]}
                                                        value={
                                                            assetsTypesOption.filter((v) => {
                                                                return v.value === currentAssets;
                                                            })[0]
                                                        }
                                                        _handleSelect={(e) => {
                                                            _handleSelectAssets(e.value, props);
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                    )) :
                                ""}

                        {/* <UnitsTable
                            theme={theme}
                            rows={rows}
                            landingAscent={landingAscent}
                            lvlCompanies={lvlCompanies}
                            role={role}
                            filteredData={filteredData}
                            deviceList={deviceList}
                            selectedCompany={selectedCompany}
                            page={page}
                            setPage={setPage}
                            _handleSelectedDetail={_handleSelectedDetail}
                            setCanbusChecked={setCanbusChecked}
                            setGpsChecked={setGpsChecked}
                            _setToDelete={_setToDelete}
                            _handleEdit={_handleEdit}
                            checked={gpsChecked}
                        /> */}

                        <AddVehicleModal
                            AddVehicleIcon
                            theme={theme}
                            currentAssets={currentAssets}
                        />

                        <AddVehicleMinMaxModal
                            AddVehicleIcon
                            theme={theme}
                            currentAssets={currentAssets}
                        />

                        <ConfirmationModal theme={theme} />

                        {/* data table with jquery */}

                        <div
                            style={cardStyle1}


                        >
                            <div className="col-xl-10 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-0"
                                style={{ width: '100%' }}
                            >
                                <div style={{ height: '90%', width: '100%' }}>
                                    <UnitsDataTableJQ
                                        Colors={Colors}
                                        theme={theme}
                                        _handleSelectedDetail={_handleSelectedDetail}
                                        filteredData={filteredData}
                                        lvlCompanies={lvlCompanies}
                                        rows={rows}
                                        landingAscent={landingAscent}
                                        role={role}
                                        selectedCompany={selectedCompany}
                                        page={page}
                                        setPage={setPage}
                                        deviceList={deviceList}
                                        setCanbusChecked={setCanbusChecked}
                                        setGpsChecked={setGpsChecked}
                                        _setToDelete={_setToDelete}
                                        _handleEdit={_handleEdit}
                                        checked={gpsChecked}
                                    />


                                </div>
                            </div>
                        </div>

                        <AddVehicleModalJQ
                            AddVehicleIcon
                            theme={theme}
                            currentAssets={currentAssets}
                            emissionTypeUnits={emissionTypeUnits}
                        />

                        <ConfirmationModalJQ theme={theme} />

                        <AssetProfileModal
                            {...props}
                            theme={props.theme}
                            deviceData={selectedDeviceData}
                            typeOptions={typeOptions}
                            selectedType={selectedType}
                            _handleSelectType={_handleSelectType}
                            tableIndex={tableIndex}
                            _handleTextChange={_handleTextChange}
                            fetchUpdateDevice={fetchUpdateDevice}
                        />
                        <AssetSettingModal
                            {...props}
                            theme={props.theme}
                            deviceData={selectedDeviceData}
                            typeOptions={typeOptions}
                            selectedType={selectedType}
                            _handleSelectType={_handleSelectType}
                            tableIndex={tableIndex}
                            _handleTextChange={_handleTextChange}
                            fetchUpdateDevice={fetchUpdateDevice}
                        />
                        <UnitsReportPreviewModal theme={theme} deviceList={deviceList} />
                    </>
                )}
            </div>
        );
    })
);

{
    /* {selectedType === "equipment" ? (
          <MyTable
            createData={(uid, company, equipment_name, brand, model) => {
              return { uid, company, equipment_name, brand, model };
            }}
            Key={["company", "equipment_name", "brand", "model"]}
            theme={props.theme}
            equipmentData={filteredDeviceList}
            header={[
              // { id: "company", label: "Company" },
              { id: "equipment_name", label: "Equipment Name" },
              { id: "brand", label: "Brand" },
              { id: "model", label: "Model" },
            ]}
            _handleSelectDevice={_handleSelectDevice}
            setTableIndex={setTableIndex}
          />
        ) : (
          <div className="text-center">
            <i className="fa fa-exclamation-triangle pe-2 text-warning" />
            No Equipment Found!
          </div>
        )} */
}
