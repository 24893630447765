import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import {
  fetchCalculatedDashboardData,
  fetchCertificateLst,
  fetchCertificateLstV2,
  fetchCompanyYears,
  fetchDataImportAccess,
  fetchStacsCertificates,
  fetchMonthlyCarbonData,
} from "../network/fetchDashboard";
import { baseURL } from "../network/URL";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

class Dashboard {
  cookies = new Cookies();
  companyType = this.cookies.get("companyType") || "1";
  token = this.cookies.get("accessToken");
  loading = false;
  currentYear = new Date().getFullYear();
  currentMonth = 0;
  currentAssets = "0";
  certificateYear = new Date().getFullYear();
  selectedYear = new Date().getFullYear();
  certificateYears = null;
  certificates = null;
  selectedCertificateId = null;
  certificateView = false;
  regView = false;
  regCertificates = null;

  certificatesV2 = null;
  certificateYearsV2 = null;

  //logs toast
  receivedUserAction = 0;

  vehicleCount = undefined;
  deviceCount = undefined;
  totalFuelData = undefined;
  dashboardData = undefined;

  primaryFuelTypeForCompany = null;

  companyyearlydata = [];
  showLogView = "logs";
  years = [];
  dashboardYears = [];
  companyId = this.cookies.get("company");
  userId = this.token && jwt_decode(this.token).userId;
  role = this.token && jwt_decode(this.token).role;

  selectedLogo = this.cookies.get("logo");

  noCertificatesData = false;
  showCertiTour = false;

  access_status = "";
  Scope1 = true;
  Scope2 = true;
  Scope3 = true;
  scope1_baselineCo2Emission = "";
  scope2_baselineCo2Emission = "";
  scope3_baselineCo2Emission = "";

  scope1_currentCo2Emission = "";
  scope2_currentCo2Emission = "";
  scope3_currentCo2Emission = "";

  scope1_baselineCo2EmissionEHr = "";
  scope2_baselineCo2EmissionEHr = "";
  scope3_baselineCo2EmissionEHr = "";

  scope1_currentCo2EmissionEHr = "";
  scope2_currentCo2EmissionEHr = "";
  scope3_currentCo2EmissionEHr = "";

  monthlyScopeData = [];
  scope1_Emission = [];
  scope2_Emission = "";
  scope3_Emission = "";
  checkScope = "scope1";

  scope1CheckData = null;
  scope2CheckData = null;
  scope3CheckData = null;

  selectedUnitType = "all";
  canbusAndGps = 0;
  gps = 0;
  noCanbusAndGps = 0;
  totalUnits = 0;

  scopeChartData = null;

  units = [];

  constructor() {
    this.fetchCertificateList = this.fetchCertificateList.bind(this);
    this.setSelectedCertificateId = this.setSelectedCertificateId.bind(this);
    makeObservable(this, {
      scope1CheckData: observable,
      scope2CheckData: observable,
      scope3CheckData: observable,
      totalUnits: observable,
      canbusAndGps: observable,
      gps: observable,
      noCanbusAndGps: observable,
      selectedYear: observable,
      scope1_Emission: observable,
      scope2_Emission: observable,
      scope3_Emission: observable,
      checkScope: observable,
      Scope1: observable,
      Scope2: observable,
      Scope3: observable,
      dashboardYears: observable,
      loading: observable,
      currentYear: observable,
      currentMonth: observable,
      companyId: observable,
      userId: observable,
      showLogView: observable,
      selectedUnitType: observable,
      units: observable,

      years: observable,
      //logs toast
      receivedUserAction: observable,

      certificateView: observable,
      vehicleCount: observable,
      deviceCount: observable,
      totalFuelData: observable,
      currentAssets: observable,
      regCertificates: observable,

      companyyearlydata: observable,
      primaryFuelTypeForCompany: observable,
      monthlyScopeData: observable,

      access_status: observable,
      setSelectedYear: action.bound,
      setLoading: action.bound,
      setCurrentYear: action.bound,
      setCurrentMonth: action.bound,
      handleViewChange: action.bound,
      _handleViewCertificate: action.bound,
      _handleViewREGCertificate: action.bound,
      _handleFetchCalculatedDashboardData: action.bound,

      certificateYear: observable,
      certificateYears: observable,
      certificates: observable,
      certificateYearsV2: observable,
      certificatesV2: observable,
      selectedCertificateId: observable,
      noCertificatesData: observable,
      showCertiTour: observable,

      setCertificateYear: action.bound,

      dashboardData: observable,
      selectedLogo: observable,

      scopeChartData: observable,

      //logs toast
      setUser: action.bound,

      _handleQueryParam: action.bound,
      _handleSelectAssets: action.bound,
      _handleFetchCertificates: action.bound,
      _handleFetchCertificatesV2: action.bound,
      _handleFetchStacsCertificates: action.bound,
      _handleFetchCompanyYears: action.bound,
      _handleFetchDataImportAccess: action.bound,
      _handleFetchMonthlyCarbonData: action.bound,
      setNoCertificatesData: action.bound,
      setShowCertiTour: action.bound,
      _handleCheckType: action.bound,
      _handleScopeChange: action.bound,
      _handleUnitTypeSelect: action.bound,
    });
  }

  _handleUnitTypeSelect(selectedType) {
    this.selectedUnitType = selectedType;
    const filterUnit = this._filterUnits(selectedType);
    let gps = 0;
    let canbusAndGps = 0;
    let noCanbusAndGps = 0;
    for (let unit of filterUnit) {
      if (unit.CANbus === 1 && unit.GPS === 1) {
        canbusAndGps += 1;
      } else if (unit.GPS === 1) {
        gps += 1;
      } else {
        noCanbusAndGps += 1;
      }
    }

    this.canbusAndGps = canbusAndGps;
    this.gps = gps;
    this.noCanbusAndGps = noCanbusAndGps;
    this.totalUnits = filterUnit.length;
  }

  _filterUnits(type) {
    if (type === "all") {
      return this.units;
    } else if (type === "ice") {
      return this.units.filter((a) => a.type === "ice");
    } else {
      return this.units.filter((a) => a.type === "ev");
    }
  }

  _handleCheckType(e, checktype) {
    if (checktype === "Scope1") {
      this.Scope1 = e.target.checked;
      if (!this.Scope1) {
        this.scope1_baselineCo2Emission =
          this.totalFuelData.scope.scope1.baselineCo2Emission;
        this.scope1_currentCo2Emission =
          this.totalFuelData.scope.scope1.currentCo2Emission;
        this.scope1_baselineCo2EmissionEHr =
          this.totalFuelData.scope.scope1.baselineCo2EmissionEHr;
        this.scope1_currentCo2EmissionEHr =
          this.totalFuelData.scope.scope1.currentCo2EmissionEHr;

        this.totalFuelData.scope.scope1.baselineCo2Emission = 0;
        this.totalFuelData.scope.scope1.currentCo2Emission = 0;
        this.totalFuelData.scope.scope1.baselineCo2EmissionEHr = 0;
        this.totalFuelData.scope.scope1.currentCo2EmissionEHr = 0;
      } else if (this.Scope1) {
        this.totalFuelData.scope.scope1.baselineCo2Emission =
          this.scope1_baselineCo2Emission;
        this.totalFuelData.scope.scope1.currentCo2Emission =
          this.scope1_currentCo2Emission;
        this.totalFuelData.scope.scope1.baselineCo2EmissionEHr =
          this.scope1_baselineCo2EmissionEHr;
        this.totalFuelData.scope.scope1.currentCo2EmissionEHr =
          this.scope1_currentCo2EmissionEHr;
      }
    } else if (checktype === "Scope2") {
      this.Scope2 = e.target.checked;
      if (!this.Scope2) {
        this.scope2_baselineCo2Emission =
          this.totalFuelData.scope.scope2.baselineCo2Emission;
        this.scope2_currentCo2Emission =
          this.totalFuelData.scope.scope2.currentCo2Emission;
        this.scope2_baselineCo2EmissionEHr =
          this.totalFuelData.scope.scope2.baselineCo2EmissionEHr;
        this.scope2_currentCo2EmissionEHr =
          this.totalFuelData.scope.scope2.currentCo2EmissionEHr;

        this.totalFuelData.scope.scope2.baselineCo2Emission = 0;
        this.totalFuelData.scope.scope2.currentCo2Emission = 0;
        this.totalFuelData.scope.scope2.baselineCo2EmissionEHr = 0;
        this.totalFuelData.scope.scope2.currentCo2EmissionEHr = 0;
      } else if (this.Scope2) {
        this.totalFuelData.scope.scope2.baselineCo2Emission =
          this.scope2_baselineCo2Emission;
        this.totalFuelData.scope.scope2.currentCo2Emission =
          this.scope2_currentCo2Emission;
        this.totalFuelData.scope.scope2.baselineCo2EmissionEHr =
          this.scope2_baselineCo2EmissionEHr;
        this.totalFuelData.scope.scope2.currentCo2EmissionEHr =
          this.scope2_currentCo2EmissionEHr;
      }
    } else {
      this.Scope3 = e.target.checked;
      if (!this.Scope3) {
        this.scope3_baselineCo2Emission =
          this.totalFuelData.scope.scope3.baselineCo2Emission;
        this.scope3_currentCo2Emission =
          this.totalFuelData.scope.scope3.currentCo2Emission;
        this.scope3_baselineCo2EmissionEHr =
          this.totalFuelData.scope.scope3.baselineCo2EmissionEHr;
        this.scope3_currentCo2EmissionEHr =
          this.totalFuelData.scope.scope3.currentCo2EmissionEHr;

        this.totalFuelData.scope.scope3.baselineCo2Emission = 0;
        this.totalFuelData.scope.scope3.currentCo2Emission = 0;
        this.totalFuelData.scope.scope3.baselineCo2EmissionEHr = 0;
        this.totalFuelData.scope.scope3.currentCo2EmissionEHr = 0;
      } else if (this.Scope3) {
        this.totalFuelData.scope.scope3.baselineCo2Emission =
          this.scope3_baselineCo2Emission;
        this.totalFuelData.scope.scope3.currentCo2Emission =
          this.scope3_currentCo2Emission;
        this.totalFuelData.scope.scope3.baselineCo2EmissionEHr =
          this.scope3_baselineCo2EmissionEHr;
        this.totalFuelData.scope.scope3.currentCo2EmissionEHr =
          this.scope3_currentCo2EmissionEHr;
      }
    }
    this.totalFuelData.baselineCo2Emission =
      Number(this.totalFuelData.scope.scope1.baselineCo2Emission) +
      Number(this.totalFuelData.scope.scope2.baselineCo2Emission) +
      Number(this.totalFuelData.scope.scope3.baselineCo2Emission);

    this.totalFuelData.baselineCo2EmissionEHr =
      Number(this.totalFuelData.scope.scope1.baselineCo2EmissionEHr) +
      Number(this.totalFuelData.scope.scope2.baselineCo2EmissionEHr) +
      Number(this.totalFuelData.scope.scope3.baselineCo2EmissionEHr);

    this.totalFuelData.currentCo2Emission =
      this.totalFuelData.scope.scope1.currentCo2Emission +
      this.totalFuelData.scope.scope2.currentCo2Emission +
      this.totalFuelData.scope.scope3.currentCo2Emission;

    this.totalFuelData.currentCo2EmissionEHr =
      Number(this.totalFuelData.scope.scope1.currentCo2EmissionEHr) +
      Number(this.totalFuelData.scope.scope2.currentCo2EmissionEHr) +
      Number(this.totalFuelData.scope.scope3.currentCo2EmissionEHr);
  }

  async setShowCertiTour(isShowCertiTour) {
    runInAction(() => {
      this.showCertiTour = isShowCertiTour;
      console.log("this.showCertiTour", this.showCertiTour);
    });
  }

  setUser(receivedUserAction) {
    let logCount = [];
    logCount.push(receivedUserAction);
    console.log("faflafjal", logCount);
    this.receivedUserAction = logCount.length;
    // this.cookies.set("logsNotiCount",this.receivedUserAction)
  }

  _handleFetchCertificates() {
    fetchCertificateLst(
      this.companyId,
      this.certificateYear,
      this.token,
      (err, data) => {
        // if (Object.keys(data?.payload)?.length === 0) {
        if (data?.payload?.length === 0) {
          this.setNoCertificatesData(true);
        } else {
          this.setNoCertificatesData(false);
        }
        this.setLoading(true);
        if (data.error) {
          toast.error(data.error);
          this.setLoading(false);
        } else {
          this.certificates = data?.payload;
          this.certificateYears = Object.keys(data.payload).map(Number);
          this.setLoading(false);
        }
      }
    );
  }

  _handleFetchCertificatesV2() {
    fetchCertificateLstV2(
      this.companyId,
      this.certificateYear,
      this.token,
      (err, data) => {
        // if (Object.keys(data?.payload)?.length === 0) {
        if (data?.payload?.length === 0) {
          this.setNoCertificatesData(true);
        } else {
          this.setNoCertificatesData(false);
        }
        this.setLoading(true);
        if (data.error) {
          toast.error(data.error);
          this.setLoading(false);
        } else {
          this.certificatesV2 = data?.payload;
          this.certificateYearsV2 = Object.keys(data.payload).map(Number);
          this.setLoading(false);
        }
      }
    );
  }

  _handleFetchCompanyYears() {
    fetchCompanyYears(this.companyId, this.token, (err, data) => {
      runInAction(() => {
        this.companyyearlydata = [];
        this.dashboardYears = [];
        this.years = [];
      });
      if (data) {
        runInAction(() => {
          this.dashboardYears.push({ label: "All", value: 0 });
          const yearlysorting = data.response.sort((a, b) => b.value - a.value);
          this.companyyearlydata = yearlysorting;

          this.years = data.response;
          // this.selectedYear = data.response

          yearlysorting.forEach((v) => {
            this.dashboardYears.push(v);
            // this.selectedYear.push(v)
          });
          console.log("dashboard year ===>", this.dashboardYears);
        });
      } else {
        toast.error(err);
      }
    });
  }

  _handleSelectAssets = async (value) => {
    this.currentAssets = value;

    this.totalFuelData = undefined;
    this.setLoading(true);
    this._handleFetchMonthlyCarbonData(this.selectedYear);
    await this._handleFetchCalculatedDashboardData();
    this.setLoading(false);
  };

  _handleQueryParam(assets, year, month) {
    this.currentYear = Number(year);
    this.selectedYear = this.currentYear;
    this.currentMonth = Number(month);
    this.currentAssets = assets;
  }

  setLoading(state) {
    this.loading = state;
  }
  setCurrentMonth = async (e, token, companyId) => {
    this.totalFuelData = undefined;
    this.setLoading(true);
    this.currentMonth = parseInt(e);
    await this._handleFetchCalculatedDashboardData();
    this.setLoading(false);
  };

  setCurrentYear = async (e, token, companyId) => {
    this.totalFuelData = undefined;
    this.setLoading(true);
    // const year = parseInt(e.target.innerHTML);
    const year = parseInt(e);
    this.currentYear = year;
    if (year === 0) {
      this.currentMonth = 0;
    }
    this.selectedYear = this.currentYear;
    await this._handleFetchCalculatedDashboardData();
    this.setLoading(false);
  };

  async setCertificateYear(year) {
    runInAction(() => {
      this.certificateYear = year;
    });
  }

  setSelectedYear(year) {
    this.monthlyScopeData = null;
    this.selectedYear = year;
  }

  _handleFetchMonthlyCarbonData(year) {
    // this.selectedYear = year
    let companyId = 0;
    let userId = 0;
    if (
      this.role === "admin" ||
      this.role === "super_admin" ||
      this.role === "operator"
    ) {
      companyId = this.companyId;
      userId = 0;
    } else {
      companyId = 0;
      userId = this.userId;
    }

    fetchMonthlyCarbonData(
      this.token,
      companyId,
      userId,
      year,
      this.companyId,
      this.currentAssets,
      (err, data) => {
        this.setLoading(true);
        if (data) {
          this.monthlyScopeData = data.payload;
          // this.scopeChartData = this.monthlyScopeData?.map(v => {
          //   let data = {
          //     scope1: v.scope1_emissions.baseline_co2_emission
          //   }
          // })
          this.setLoading(false);
        } else {
          toast.error(data?.error);
        }
      }
    );
  }

  _handleScopeChange(checkScope) {
    if (checkScope === "scope1") {
      // this.Scope1 = e.target.checked;
      // console.log("data==>",toJS(this.monthlyScopeData))
      this.scope1_Emission = this.monthlyScopeData?.map((v) =>
        toJS(v?.scope1_emissions)
      );
      // console.log("scope1", this.scope1_Emission);
    } else if (checkScope === "scope2") {
      this.scope2_Emission = this.monthlyScopeData?.map((v) =>
        toJS(v?.scope2_emissions)
      );
    } else if (checkScope === "scope3") {
      this.scope3_Emission = this.monthlyScopeData?.map((v) =>
        toJS(v?.scope3_emissions)
      );
    }
  }

  async setSelectedCertificateId(id) {
    runInAction(() => {
      this.selectedCertificateId = id;
    });
  }

  _handleFetchStacsCertificates() {
    fetchStacsCertificates(this.companyId, this.token, (err, data) => {
      if (data) {
        this.regCertificates = data;
      }
    });
  }

  _handleFetchDataImportAccess() {
    fetchDataImportAccess(this.companyId, this.token, (err, data) => {
      if (data) {
        this.access_status = data.payload;
        // console.log("access_status=====>",data.payload)
      } else {
        toast.error(err);
      }
    });
  }

  async fetchCertificateList(companyId) {
    try {
      const response = await fetch(
        `${baseURL}/certificate/certificateList?companyId=${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Access-Token": this.token,
          },
        }
      );
      const data = await response.json();
      runInAction(() => {
        this.certificates = data;
        this.certificateYears = Object.keys(data).map(Number);
      });
    } catch (e) {
      console.log(e);
    }
  }

  setMonitorLoading(loading, type) {
    this.monitorLoading = { ...this.monitorLoading, [type]: loading };
  }
  observable;

  _handleViewCertificate() {
    runInAction(() => {
      this.certificateView = !this.certificateView;
    });
  }

  _handleFetchCalculatedDashboardData = async () => {
    let data1;
    runInAction(() => {
      this.dashboardData = null;
      this.loading = true;
    });

    let companyId = 0;
    let userId = 0;

    console.log("role", this.role);

    if (
      this.role === "admin" ||
      this.role === "super_admin" ||
      this.role === "operator"
    ) {
      companyId = this.companyId;
      userId = 0;
    } else {
      companyId = 0;
      userId = this.userId;
    }

    console.log("userId", this.userId);

    await fetchCalculatedDashboardData(
      this.token,
      this.currentYear,
      this.currentMonth,
      this.currentAssets,
      companyId,
      userId,
      this.companyId,
      (err, data) => {
        const result = toJS(data);
        this.loading = false;
        console.log(result);
        if (result && result.payload) {
          data1 = result.payload;
          if (this.companyType === 2) {
            this.scope1CheckData = data1?.scope?.scope1.baselineCo2EmissionEHr;
            this.scope2CheckData = data1?.scope?.scope2.baselineCo2EmissionEHr;
            this.scope3CheckData = data1?.scope?.scope3.baselineCo2EmissionEHr;
          } else {
            this.scope1CheckData = data1?.scope?.scope1.baselineCo2Emission;
            this.scope2CheckData = data1?.scope?.scope2.baselineCo2Emission;
            this.scope3CheckData = data1?.scope?.scope3.baselineCo2Emission;
          }

          this.vehicleCount = data1.vehicleCount;
          this.deviceCount = data1.deviceCount;
          this.totalFuelData = data1;
          this.units = data1.company_units;
          this._handleUnitTypeSelect("all");
        } else if (result && result.error) {
          toast.error(data.error);
        }
      }
    );

    // runInAction(() => {
    //   this.totalFuelData = data1;

    // });
  };

  setNoCertificatesData(value) {
    this.noCertificatesData = value;
  }

  handleViewChange(view) {
    runInAction(() => {
      this.showLogView = view;
    });
  }

  _handleViewREGCertificate() {
    runInAction(() => {
      this.regView = !this.regView;
      console.log("clicked");
    });
  }
}

export default new Dashboard();
