import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
  emission_vehicle,
  emission_ev_vehicle,
} from "../../Assets/Config/constant.config";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { max, min } from "date-fns";

export const EmissionIndicatiorFMS = inject("store")(
  observer((props) => {
    const { t } = useTranslation("new-tracking");
    const { theme } = props;
    // const theme = localStorage.getItem("theme");
    const { assetProfile, vehicleType, month, unitSetting } =
      props.store.trackingVTwo;
    const isLoading = !assetProfile;
    const emission =
      vehicleType.value === "Battery" ? emission_ev_vehicle : emission_vehicle;
    //const emissionFactorValue = assetProfile ? ((assetProfile.totalConsumed * emission * 1000) / (assetProfile.vehicleMileage)).toFixed(2) : 0;

    //update

    const initialValue = 0;

    // CANBus Total Emission
    const CANBusEmissionSum = assetProfile?.live?.overAllCarbonEmissionFactor;

    // Manual Total Emission
    const ManualEmissionSum = assetProfile?.live?.overAllCarbonEmissionFactor;

    const liveManualEmissionSum =
      assetProfile?.live_manual?.overAllCarbonEmissionFactor;

    const monthly_emission_factor_data = assetProfile
      ? assetProfile?.carbonEmissionsByMonth?.filter(
          (data) => data.month == month
        )[0]
      : {};

    // const emissionFactor = monthly_emission_factor_data
    //   ? monthly_emission_factor_data.emissionFactor
    //   : 0;

    // const emissionFactor =
    //   ManualEmissionSum == 0 || ManualEmissionSum?.length < 0
    //     ? CANBusEmissionSum
    //     : ManualEmissionSum;

    const emissionFactor =
      unitSetting?.enableCANbus && unitSetting?.enableGPS
        ? CANBusEmissionSum
        : !unitSetting?.enableCANbus && !unitSetting?.enableGPS
        ? ManualEmissionSum
        : liveManualEmissionSum;

    // const baselineEmissionFactor = assetProfile
    //   ? (assetProfile.emission_type == "Historical Data"
    //       ? assetProfile.oem_history
    //       : assetProfile.manualBaselineEmissionPerKm)
    //   : 0;

    const baselineEmissionFactor = assetProfile?.baseline_emission_factor;

    //const emissionFactor = emissionFactorValue != 'NaN' ? emissionFactorValue : '0';
    // const minValue = vehicleType.value === "Battery" ? 110 : 1100;
    // const maxValue = vehicleType.value === "Battery" ? 0 : 500;

    const minValue = 0;
    const maxValue = Number(baselineEmissionFactor) * 2;

    const segments = 3;
    const segmentSize = (maxValue - minValue) / segments;

    // const segmentRanges = [
    //   minValue + segmentSize,
    //   minValue + segmentSize + segmentSize,
    //   minValue + segmentSize + segmentSize + segmentSize,
    // ];

    const segmentRanges = [
      minValue + maxValue*0.5,
      minValue + maxValue*0.8,
      maxValue
    ]

    const middleValues = [
      minValue + segmentSize / 2,
      minValue + segmentSize + segmentSize / 2,
      maxValue - segmentSize / 2,
    ];

    let segmentValue = null;

    const [displayedValue, setDisplayedValue] = useState(0);

    if (emissionFactor < segmentRanges[0]) {
      segmentValue = middleValues[0];
      console.log("one");
    } else if (
      emissionFactor >= segmentRanges[0] &&
      emissionFactor < segmentRanges[1]
    ) {
      segmentValue = middleValues[1];
      console.log("two");
    } else {
      segmentValue = middleValues[2];
      console.log("three");
    }


    useEffect(() => {
      // Set the displayed value to 0 if the actual value is 499 or less
      // const actualValue = Number(emissionFactor);
      // if (
      //   (!isNaN(actualValue) && actualValue <= 499) ||
      //   (!isNaN(actualValue) && actualValue > 1110)
      // ) {
      //   setDisplayedValue(Number(emissionFactor).toFixed(4));
      // } else {
      //   setDisplayedValue(actualValue);
      // }
      setDisplayedValue(emissionFactor);
    }, [emissionFactor]);


    return !isLoading ? (
      <div
        className="d-flex row align-items-center justify-content-center text-center m-0 h-100"
        style={{ color: invertDark(theme) }}
      >
        <span>
          <strong>{t("Baseline Emission Factor")}:</strong>{" "}
          {baselineEmissionFactor} {assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"}
        </span>
        {emissionFactor > minValue || emissionFactor < maxValue ? (
          // <div
          //   style={{
          //     background: theme === "dark" ? Colors.darkBlue : Colors.Dark[4],
          //     width: 255,
          //   }}
          //   className="py-2 rounded"
          // >
          //   <i
          //     className="fa fa-exclamation-triangle pe-2"
          //     style={{ color: Colors.fuel }}
          //   />
          //   {emissionFactor > minValue
          //     ? t(
          //       `Overall Carbon Emission factor has exceeded ${minValue} gCO2e/kmOverall Carbon Emission factor has exceeded ${minValue} gCO2e/km`
          //     )
          //     : emissionFactor < maxValue ?
          //       t(`Overall Carbon Emission factor (gCO₂e/km) is lower than ${maxValue}.`)
          //       :
          //       t("Incomplete Data")}
          // </div>
          emissionFactor > minValue ? (
            <>
              <ReactSpeedometer
                minValue={minValue}
                maxValue={maxValue}
                maxSegmentLabels={1}
                customSegmentStops={[minValue, segmentRanges[0], segmentRanges[1], segmentRanges[2]]}
                
                segmentColors={[
                  "#00ff00",
                  // "#ECF87F",
                  // "#FFFF00",
                  "#FFA500",
                  "#ff0000",
                ]}
                segments={3}
                width={200}
                height={125}
                ringWidth={10}
                needleHeightRatio={0.8}
                // value={Number(
                //   emissionFactor != "NaN"
                //     ? vehicleType.value === "Battery"
                //       ? 116
                //       : 1120
                //     : 0
                // )}
                value={emissionFactor}
                valueFormat={","}
                currentValueText={Number(displayedValue).toFixed(2)}
                needleColor={Colors.lightBlue}
                textColor={invertDark(theme)}
                valueTextFontWeight={200}
              />
              <span style={{ fontSize: "14px" }}>
                Overall Carbon Emission Factor ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
              </span>
            </>
          ) : emissionFactor < maxValue ? (
            <>
              <ReactSpeedometer
                minValue={minValue}
                maxValue={maxValue}
                maxSegmentLabels={1}
                customSegmentStops={[minValue, segmentRanges[0], segmentRanges[1], segmentRanges[2]]}
                
                segmentColors={[
                  "#00ff00",
                  // "#ECF87F",
                  // "#FFFF00",
                  "#FFA500",
                  "#ff0000",
                ]}
                segments={3}
                width={200}
                height={125}
                ringWidth={10}
                needleHeightRatio={0.8}
                // value={Number(emissionFactor != "NaN" ? 480 : 0)}
                value={emissionFactor}
                valueFormat={","}
                currentValueText={Number(displayedValue).toFixed(2)}
                needleColor={Colors.lightBlue}
                textColor={invertDark(theme)}
                valueTextFontWeight={200}
              />
              <span style={{ fontSize: "14px" }}>
                Overall Carbon Emission Factor ({assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})
                {maxValue}.
              </span>
            </>
          ) : (
            ""
          )
        ) : (
          <>
            <ReactSpeedometer
              minValue={minValue}
              maxValue={maxValue}
              maxSegmentLabels={1}
              customSegmentStops={[minValue, segmentRanges[0], segmentRanges[1], segmentRanges[2]]}
              // customSegmentStops={[0,304.4, 456.6, 608.08 ]}

              segmentColors={[
                "#00ff00",
                // "#ECF87F",
                // "#FFFF00",
                "#FFA500",
                "#ff0000",
              ]}
              segments={3}
              width={200}
              height={125}
              ringWidth={10}
              needleHeightRatio={0.8}
              // value={Number(emissionFactor != "NaN" ? emissionFactor : 0)}
              value={emissionFactor}
              currentValueText={Number(displayedValue).toFixed(2)}
              valueFormat={","}
              needleColor={Colors.lightBlue}
              textColor={invertDark(theme)}
              valueTextFontWeight={200}
            />
            <span className="w-100 mb-0 fw-bold">
              {t(`Overall Carbon Emission Factor (${assetProfile?.emission_type_unit === "gCO2e/km" ? "gCO₂e/km" : "gCO₂e/h"})`)}
            </span>
          </>
        )}
      </div>
    ) : (
      "Loading..."
    );
  })
);
